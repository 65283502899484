<template>
  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="md-fixed"
    :class="{
      [`md-${type}`]: !navbarTransparent,
      [extraNavClasses]: navbarTransparent,
      'md-transparent': navbarTransparent,
    }"
    :color-on-scroll="colorOnScroll"
  >
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start">
        <a href="/">
          <h3 class="md-title">
            <img src="../assets/img/ARimg/LogoAR.png" width="50%" />
          </h3>
        </a>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: toggledClass }"
          @click="toggleNavbarMobile()"
        >
          <span class="icon-bar" style="color: white" />
          <span class="icon-bar" style="color: white" />
          <span class="icon-bar" style="color: white" />
        </md-button>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section="false">
              <!-- Here you can add your items from the section-start of your toolbar -->
            </mobile-menu>

            <md-list>
              <md-list-item href="/">
                <a href="/" target="_blank">
                  <i class="material-icons">home</i>
                  {{ navbarLanguage.home }}
                </a>
              </md-list-item>

              <md-list-item href="/backtest">
                <a target="_blank" href="/backtest">
                  {{ navbarLanguage.back_test }}
                </a>
              </md-list-item>
              <md-list-item href="/price">
                <a href="/price" target="_blank">
                  <i class="material-icons">monetization_on</i>
                  {{ navbarLanguage.price }}
                </a>
              </md-list-item>
              <md-list-item href="/reviews">
                <a href="/reviews" target="_blank">
                  {{ navbarLanguage.reviews }}
                </a>
              </md-list-item>

              <li class="md-list-item">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                        slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <i class="material-icons">apps</i>
                        <p>{{ navbarLanguage.knowledge }}</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li>
                          <a target="_blank" href="/knowledge/autorebalance">
                            <i class="material-icons">line_style</i>
                            {{ navbarLanguage.auto_rebalance }}
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="/strategies">
                            <i class="material-icons">offline_bolt</i>
                            {{ navbarLanguage.strategies }}
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="/blogsNews">
                            <i class="material-icons">content_paste</i>
                            {{ navbarLanguage.new_blok }}
                          </a>
                        </li>
                        <li>
                          <a target="_blank" href="/FAQ">
                            <i class="material-icons">announcement</i>
                            {{ navbarLanguage.faq }}
                          </a>
                        </li>

                        <li>
                          <a target="_blank" href="/podcast">
                            <i class="material-icons">keyboard_voice</i>
                            {{ navbarLanguage.podcast }}
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://autorebalance.gitbook.io/autorebalance/?fbclid=IwAR0biUUA9C6nKHbtcjejMj6e3SOgRkjnWRI-F3OY5wkgl5XxaCgmmLaiIW0"
                          >
                            <i class="material-icons">format_quote</i>
                            {{ navbarLanguage.document }}
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>

              <md-list-item href="/contact-us">
                <a target="_blank" href="/contact-us">
                  {{ navbarLanguage.contract }}
                </a>
              </md-list-item>

              <md-list-item>
                <a @click="changeLanguage()" href="#">
                  <div
                    style="border-style: solid; padding: 5px"
                    class="mobile_size"
                  >
                    {{ language }}
                  </div>
                </a>
              </md-list-item>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

import MobileMenu from "@/layout/MobileMenu";

import { mapGetters, mapActions } from "vuex";

import navbar_en from "../language/navbar_en";
import navbar_th from "../language/navbar_th";

export default {
  components: {
    MobileMenu,
  },
  props: {
    type: {
      type: String,
      default: "primary",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info",
          "dark",
        ].includes(value);
      },
    },
    colorOnScroll: {
      type: Number,
      default: 0,
    },
    navbarTransparent: {
      type: Boolean,
      default: false,
    },
  },
  computed: mapGetters(["getLanguage"]),

  data() {
    return {
      docs_link:
        "https://demos.creative-tim.com/vue-material-kit-pro/documentation/",
      extraNavClasses: "",
      toggledClass: false,
      language: "THAI",

      navbarLanguage: {},
    };
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    ...mapActions(["setLanguage"]),
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
    handleScroll() {
      let scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop;
      let navbarColor = document.getElementById("toolbar");
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `md-${this.type}`;
        navbarColor.classList.remove("md-transparent");
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
          navbarColor.classList.add("md-transparent");
        }
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    },
    changeLanguage() {
      if (this.getLanguage == "THAI") {
        this.language = "THAI";

        this.setLanguage("ENG");

        this.navbarLanguage = navbar_en;
      } else {
        this.language = "ENG.";

        this.setLanguage("THAI");

        this.navbarLanguage = navbar_th;
      }
    },
  },
  created() {
    if (this.getLanguage == "THAI") {
      this.language = "ENG";

      this.navbarLanguage = navbar_th;
    } else if (this.getLanguage == "ENG") {
      this.language = "THAI";

      this.navbarLanguage = navbar_en;
    } else {
      this.setLanguage("THAI");

      this.navbarLanguage = navbar_th;
    }
  },
};
</script>

<style lang="scss">
.mobile_size {
  color: white;
}

@media all and (max-width: 768px) {
  .mobile_size {
    color: black;
  }
}
</style>

<template>
  <div class="wrapper">
    <!-- Features 1 -->
    <div
      class="section"
      style="background-color: #001a26; color: white; margin-top: -100px"
    >
      <div class="container">
        <div
          class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
        >
          <div v-if="getLanguage == 'THAI'">
            <h2 class="text-orange">
              <b style="font-size: 40px; font-weight: 700">{{ th_strategy }}</b>
            </h2>
          </div>
          <div class="text-orange" v-else>
            <h2>
              <b style="font-size: 40px; font-weight: 700">{{ en_strategy }}</b>
            </h2>
          </div>
        </div>

        <div class="md-layout">
          <carousel-3d autoplay="1">
            <slide :index="0" class="card">
              <div class="section-1">
                <img
                  src="../../assets/img/ARimg/Strategy-24.svg"
                  alt="Avatar"
                  style="width: 40%; padding-top: 15px"
                />

                <div class="textcard">
                  <b>MAIN MARKET</b>
                  <b>REBALANCE</b>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: flex-end;
                    padding: 10px;
                  "
                >
                  <a
                    style="
                      background-color: #39b34a;
                      border-radius: 5px;
                      border-color: white;
                      padding: 5px;
                      color: white;
                      font-size: 12px;
                    "
                  >
                    Read more >>
                  </a>
                </div>
              </div>
            </slide>
            <slide :index="1" class="card">
              <div class="section-1">
                <img
                  src="../../assets/img/ARimg/Strategy-25.svg"
                  alt="Avatar"
                  style="width: 40%; padding-top: 15px"
                />

                <div class="textcard">
                  <b>FUSION</b>
                  <b>REBALANCE</b>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: flex-end;
                    padding: 10px;
                  "
                >
                  <a
                    style="
                      background-color: #39b34a;
                      border-radius: 5px;
                      border-color: white;
                      padding: 5px;
                      color: white;
                      font-size: 12px;
                    "
                  >
                    Read more >>
                  </a>
                </div>
              </div>
            </slide>
            <slide :index="2" class="card">
              <div class="section-1">
                <img
                  src="../../assets/img/ARimg/Strategy-26.svg"
                  alt="Avatar"
                  style="width: 40%; padding-top: 15px"
                />

                <div class="textcard">
                  <b>MULTI</b>
                  <b>REBALANCE</b>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: flex-end;
                    padding: 10px;
                  "
                >
                  <a
                    style="
                      background-color: #39b34a;
                      border-radius: 5px;
                      border-color: white;
                      padding: 5px;
                      color: white;
                      font-size: 12px;
                    "
                  >
                    Read more >>
                  </a>
                </div>
              </div>
            </slide>
            <slide :index="3" class="card">
              <div class="section-1">
                <img
                  src="../../assets/img/ARimg/Strategy-27.svg"
                  alt="Avatar"
                  style="width: 40%; padding-top: 15px"
                />

                <div class="textcard">
                  <b>FIAT</b>
                  <b>REBALANCE</b>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: flex-end;
                    padding: 10px;
                  "
                >
                  <a
                    style="
                      background-color: #39b34a;
                      border-radius: 5px;
                      border-color: white;
                      padding: 5px;
                      color: white;
                      font-size: 12px;
                    "
                  >
                    Read more >>
                  </a>
                </div>
              </div>
            </slide>
            <slide :index="4" class="card">
              <div class="section-1">
                <img
                  src="../../assets/img/ARimg/Strategy-28.svg"
                  alt="Avatar"
                  style="width: 40%; padding-top: 15px"
                />

                <div class="textcard">
                  <b>ULTIMATE</b>
                  <b>REBALANCE</b>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: flex-end;
                    padding: 10px;
                  "
                >
                  <a
                    style="
                      background-color: #39b34a;
                      border-radius: 5px;
                      border-color: white;
                      padding: 5px;
                      color: white;
                      font-size: 12px;
                    "
                  >
                    Read more >>
                  </a>
                </div>
              </div>
            </slide>
          </carousel-3d>
        </div>
      </div>
    </div>
    <!-- end Features 1 -->

    <!-- end Features 5 -->
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";

import { mapGetters } from "vuex";

import { en_strategy, en_reviews } from "../../language/home_en";
import { th_strategy, th_reviews } from "../../language/home_th";

export default {
  name: "strategy",

  components: {
    Carousel3d,
    Slide,
  },

  data() {
    return {
      iphone: require("@/assets/img/sections/iphone.jpg"),
      iphone2: require("@/assets/img/sections/iphone2.png"),
      image: require("@/assets/img/bg9.jpg"),
      en_strategy,
      en_reviews,
      th_strategy,
      th_reviews,
    };
  },
  computed: mapGetters(["getLanguage"]),
};
</script>

<style lang="scss">
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  color: black;
  background-image: linear-gradient(#ffffff, #cacaca);
  transition: 0.3s;
  border-radius: 10px;
  width: 100%;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 2px 16px;
}

.section-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.textcard {
  display: flex;
  font-size: larger;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 12px;
  line-height: 1.5;
}
</style>

const state = {
  language: JSON.parse(localStorage.getItem("Language")) || null,
};

const getters = {
  getLanguage: (state) => state.language,
};

const actions = {
  setLanguage({ commit }, language) {
    commit("setLanguage", language);
  },
};

const mutations = {
  setLanguage(state, language) {
    state.language = language;
    localStorage.setItem("Language", JSON.stringify(language));
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="wrapper">
    <div class="section section-header-2 pt-0 pb-0">
      <div
        class="page-header"
        style="background-image: linear-gradient(#000000, #001a26)"
      >
        <div class="container">
          <div class="md-layout" style="margin-top: 170px">
            <div class="md-layout-item md-size-60 md-small-size-100">
              <h1
                style="
                  font-weight: 900;
                  color: white;
                  letter-spacing: 5px;
                  line-height: 1;
                  /* text-shadow: 2px 3px 6px ; */
                "
                v-if="getLanguage == 'THAI'"
              >
                {{ title_th }}
              </h1>
              <h1
                style="
                  font-weight: 900;
                  color: white;
                  letter-spacing: 5px;
                  line-height: 1;
                  /* text-shadow: 2px 3px 6px ; */
                "
                v-else
              >
                {{ title_en }}
              </h1>

              <h1
                style="
                  padding-top: 20px;
                  font-size: 30px;
                  font-weight: 900;
                  color: white;
                  letter-spacing: 5px;
                  line-height: 1;
                  /* text-shadow: 2px 3px 6px ; */
                "
                v-if="getLanguage == 'THAI'"
              >
                {{ title_2_th }}
              </h1>

              <h1
                style="
                  padding-top: 20px;
                  font-size: 30px;
                  font-weight: 900;
                  color: white;
                  letter-spacing: 5px;
                  line-height: 1;
                  /* text-shadow: 2px 3px 6px ; */
                "
                v-else
              >
                {{ title_2_en }}
              </h1>

              <h3>
                <span style="font-weight: 600"> Auto Rebalance (AR) </span>
                <span v-if="getLanguage == 'THAI'">
                  {{ description_2_th }}
                </span>
                <span v-else>
                  {{ description_2_en }}
                </span>

                <!-- <span style="font-weight: 600">Rebalance</span> -->
              </h3>
            </div>
            <div class="md-layout-item md-size-40 md-small-size-100 boxjump">
              <center>
                <img
                  style="width: 90%"
                  src="../../assets/img/ARimg/MascotAR-01.png"
                />
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="section" style="background-color: #001a26; color: white">
      <div v-if="getLanguage == 'THAI'">
        <center>
          <h2 class="text-title">
            <b style="font-size: 40px; font-weight: 600">กิจกรรม</b>
          </h2>
        </center>

        <img
          style="padding-top: 20px"
          src="../../assets/img/strategies/even.jpeg"
        />
      </div>
      <div v-else>
        <center>
          <h2 class="text-title">
            <b style="font-size: 40px; font-weight: 800">EVENT</b>
          </h2>
        </center>

        <img
          style="padding-top: 20px"
          src="../../assets/img/strategies/even_eng.jpeg"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  description_1_th,
  description_2_th,
  title_th,
  title_2_th,
} from "../../language/home_th";
import {
  description_1_en,
  description_2_en,
  title_en,
  title_2_en,
} from "../../language/home_en";

export default {
  name: "Headers",
  components: {},
  computed: mapGetters(["getLanguage"]),

  data() {
    return {
      companyName: null,
      companyEmail: null,
      companyPassword: null,
      toggledClass: false,
      description_1_th,
      description_2_th,
      description_1_en,
      description_2_en,
      title_th,
      title_en,
      title_2_th,
      title_2_en,
      headerImages: {
        Image1: require("@/assets/img/bg12.jpg"),
        Image2: require("@/assets/img/examples/office2.jpg"),
        Image3: require("@/assets/img/dg1.jpg"),
        Image4: require("@/assets/img/dg2.jpg"),
        Image5: require("@/assets/img/favicon.png"),
      },
      windowHeight: window.innerHeight,
      home_language: {},
    };
  },
  methods: {
    header(nr) {
      return {
        backgroundImage: `url(${this.headerImages[`Image${nr}`]})`,
      };
    },
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
  },
};
</script>

<style lang="scss">
.buttons {
  display: flex;
  align-items: center;
}

#headers {
  .el-carousel__container {
    height: 100vh !important;
  }
  .el-carousel {
    border-radius: 0 !important;
  }
}

.text-right {
  text-align: right;
}

.border-radius-0 {
  border-radius: 0;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}

@media all and (max-width: 960px) {
  .md-card-form-horizontal .md-layout-item {
    margin-top: 1.5rem;
  }
}

.cssanimation,
.cssanimation span {
  animation-duration: 1.5s;
  animation-fill-mode: both;
}

.cssanimation span {
  display: inline-block;
}

.fadeInBottom {
  animation-name: fadeInBottom;
}
@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}
</style>

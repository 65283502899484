<template>
  <div class="wrapper">
    <div class="section section-testimonials-1 header-filter background-blue">
      <div class="container">
        <br />
        <center>
          <h1
            style="font-size: 30px; font-weight: 600; margin: 50px"
            class="text-orange"
          >
            <b
              v-if="getLanguage == 'THAI'"
              style="font-size: 3rem; font-weight: 800"
              >ราคา</b
            >

            <b v-else style="font-size: 3rem; font-weight: 800">PRICE</b>
          </h1>
        </center>

        <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
          <div class="md-layout">
            <div class="md-layout-item md-size-25 md-small-size-100">
              <pricing-card card-plain>
                <template slot="cardContent">
                  <h4 v-if="getLanguage == 'THAI'" style="color: white">
                    ทดลอง
                  </h4>
                  <h4 v-else style="color: white">DEMO</h4>
                  <h1 v-if="getLanguage == 'THAI'" class="card-title text-gray">
                    <template> ฟรี </template>
                  </h1>

                  <h1 v-else class="card-title text-gray">
                    <template> FREE </template>
                  </h1>
                  <ul>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>
                        <template v-if="getLanguage == 'THAI'"
                          >1 คู่เทรด</template
                        >
                        <template v-else>1 pair</template>
                      </b>
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>

                        Crypto + Fiat Market
                      </b>
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-danger"> close </md-icon>

                        Crypto + Main Market
                      </b>
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-danger"> close </md-icon>

                        Multi Rebalance
                      </b>
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-danger"> close </md-icon>

                        Ultimate Rebalance</b
                      >
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-danger"> close </md-icon>

                        Fusion Rebalance</b
                      >
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-danger"> close </md-icon>

                        AI Rebalance</b
                      >
                    </li>

                    <li>
                      <b class="text-gray">
                        <md-icon class="text-danger"> close </md-icon>

                        Web3
                        <br />
                        ( Rebalance on 1inch )
                      </b>
                    </li>
                  </ul>

                  <md-button
                    href="https://www.facebook.com/autorebalance"
                    target="_blank"
                    class="md-success md-round"
                    v-if="getLanguage == 'THAI'"
                  >
                    ซื้อบริการ
                  </md-button>

                  <md-button
                    href="https://www.facebook.com/autorebalance"
                    target="_blank"
                    class="md-success md-round"
                    v-else
                  >
                    Buy Now!
                  </md-button>
                </template>
              </pricing-card>
            </div>

            <div class="md-layout-item md-size-25 md-small-size-100">
              <pricing-card card-plain>
                <template slot="cardContent">
                  <h4 v-if="getLanguage == 'THAI'" style="color: white">
                    1 พอต 2 คู่
                  </h4>
                  <h4 v-else style="color: white">1 port 2 pair</h4>

                  <h1 class="card-title text-gray">
                    <small>$</small>
                    <template> 500 </template>
                    <small v-if="getLanguage == 'THAI'">/ ปี</small>
                    <small v-else>/ year</small>
                  </h1>
                  <ul>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>
                        <template v-if="getLanguage == 'THAI'"
                          >2 คู่เทรด</template
                        >

                        <template v-else>2 pair</template>
                      </b>
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>

                        Crypto + Fiat Market
                      </b>
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>

                        Crypto + Main Market
                      </b>
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-danger"> close </md-icon>

                        Multi Rebalance
                      </b>
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-danger"> close </md-icon>

                        Ultimate Rebalance</b
                      >
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-danger"> close </md-icon>

                        Fusion Rebalance</b
                      >
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-danger"> close </md-icon>

                        AI Rebalance</b
                      >
                    </li>

                    <li>
                      <b class="text-gray">
                        <md-icon class="text-danger"> close </md-icon>

                        Web3
                        <br />
                        ( Rebalance on 1inch )
                      </b>
                    </li>
                  </ul>

                  <md-button
                    href="https://www.facebook.com/autorebalance"
                    target="_blank"
                    class="md-success md-round"
                    v-if="getLanguage == 'THAI'"
                  >
                    ซื้อบริการ
                  </md-button>

                  <md-button
                    href="https://www.facebook.com/autorebalance"
                    target="_blank"
                    class="md-success md-round"
                    v-else
                  >
                    Buy Now!
                  </md-button>
                </template>
              </pricing-card>
            </div>

            <div class="md-layout-item md-size-25 md-small-size-100">
              <pricing-card card-plain>
                <template slot="cardContent">
                  <h4 v-if="getLanguage == 'THAI'" style="color: white">
                    1 พอต 3 คู่
                  </h4>
                  <h4 v-else style="color: white">1 port 3 pair</h4>

                  <h1 class="card-title text-gray">
                    <small>$</small>
                    <template> 750 </template>
                    <small v-if="getLanguage == 'THAI'">/ ปี</small>
                    <small v-else>/ year</small>
                  </h1>
                  <ul>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>
                        <template v-if="getLanguage == 'THAI'"
                          >3 คู่เทรด</template
                        >

                        <template v-else>3 pair</template>
                      </b>
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>

                        Crypto + Fiat Market
                      </b>
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>

                        Crypto + Main Market
                      </b>
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>

                        Multi Rebalance
                      </b>
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>

                        Ultimate Rebalance</b
                      >
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-danger"> close </md-icon>

                        Fusion Rebalance</b
                      >
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-danger"> close </md-icon>

                        AI Rebalance</b
                      >
                    </li>

                    <li>
                      <b class="text-gray">
                        <md-icon class="text-danger"> close </md-icon>

                        Web3
                        <br />
                        ( Rebalance on 1inch )
                      </b>
                    </li>
                  </ul>

                  <md-button
                    href="https://www.facebook.com/autorebalance"
                    target="_blank"
                    class="md-success md-round"
                    v-if="getLanguage == 'THAI'"
                  >
                    ซื้อบริการ
                  </md-button>

                  <md-button
                    href="https://www.facebook.com/autorebalance"
                    target="_blank"
                    class="md-success md-round"
                    v-else
                  >
                    Buy Now!
                  </md-button>
                </template>
              </pricing-card>
            </div>

            <div class="md-layout-item md-size-25 md-small-size-100">
              <pricing-card>
                <template slot="cardContent">
                  <h4 v-if="getLanguage == 'THAI'">ไม่อั้นคู่เทรด</h4>
                  <h4 v-else>Unlimite</h4>

                  <h1 class="card-title text-gray">
                    <small>$</small>
                    <template> 1,000 </template>
                    <small v-if="getLanguage == 'THAI'">/ ปี</small>
                    <small v-else>/ year</small>
                  </h1>
                  <ul>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>

                        <template v-if="getLanguage == 'THAI'"
                          >ไม่อั้นคู่เทรด</template
                        >

                        <template v-else>Unlimite</template>
                      </b>
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>

                        Crypto + Fiat Market
                      </b>
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>

                        Crypto + Main Market
                      </b>
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>

                        Multi Rebalance
                      </b>
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>

                        Ultimate Rebalance</b
                      >
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>

                        Fusion Rebalance</b
                      >
                    </li>
                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>

                        AI Rebalance</b
                      >
                    </li>

                    <li>
                      <b class="text-gray">
                        <md-icon class="text-success"> check </md-icon>

                        Web3
                        <br />
                        ( Rebalance on 1inch )
                      </b>
                    </li>
                  </ul>

                  <a> </a>

                  <md-button
                    href="https://www.facebook.com/autorebalance"
                    target="_blank"
                    class="md-primary md-round"
                  >
                    <template> Buy Now! </template>
                  </md-button>
                </template>
              </pricing-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav id="cd-vertical-nav" class="vertical-nav-active">
      <ul>
        <li>
          <a
            href="https://www.facebook.com/autorebalance"
            target="_blank"
            data-number="1"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #0f47f7"
                class="fab fa-facebook"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a
            href="http://line.me/ti/g/fqQ5X1GmYP"
            target="_blank"
            data-number="2"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #5afa33"
                class="fab fa-line"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a
            href="https://www.instagram.com/aiautorebalance/"
            target="_blank"
            data-number="3"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #fa33af"
                class="fab fa-instagram"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a
            href="https://medium.com/@aiautorebalance"
            target="_blank"
            data-number="4"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: white"
                class="fab fa-medium"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { PricingCard } from "@/components";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "Price",
  components: { PricingCard },
  computed: mapGetters(["getLanguage"]),

  data() {
    return {
      testimonials1: require("@/assets/img/dg2.jpg"),
      cardBlogPost: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2022/02/S__5226628.jpg",
      },
      cardBlogPost2: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2021/03/1_A1x1XR7PJEhZiSqSNWxvvw.png",
      },
      cardBlogPost3: {
        cardBlogPost1:
          "https://scontent.fbkk2-4.fna.fbcdn.net/v/t39.30808-6/274545843_493958235592183_8891905078477806124_n.jpg?_nc_cat=101&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeFtwmjIM0s5aKuP3FP_EKWE9KvFs1u-Bsj0q8WzW74GyHof3DbJchYdAFLJSwOAt-0jBuSrShuHTLMHvmqHAnGz&_nc_ohc=Rc2aeFz0ZeEAX8GMXjR&tn=j0WnZqtCr_hzpKka&_nc_ht=scontent.fbkk2-4.fna&oh=00_AT_hRjG8shrQy4ER-lJWkKeH9omKC4oRT-YFgJqbFjAuog&oe=622664C0",
      },
    };
  },
  methods: {
    sectionTestimonials(image) {
      return {
        backgroundImage: `url(${image})`,
      };
    },
  },
};
</script>

<style lang="scss">
.vertical-nav-active {
  display: block;
}
</style>

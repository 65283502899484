<template>
  <footer
    class="footer"
    :class="{
      [`footer-${type}`]: type,
      [`footer-${size}`]: size,
    }"
    :data-background-color="backgroundColor"
  >
    <template>
      <div class="container">
        <nav style="color: white">
          <ul>
            <li>
              <a href="/" v-if="getLanguage == 'THAI'">
                {{ navbar_th.home }}
              </a>
              <a href="/" v-else>
                {{ navbar_en.home }}
              </a>
            </li>
            <li>
              <a href="/backtest" v-if="getLanguage == 'THAI'">
                {{ navbar_th.back_test }}
              </a>
              <a href="/backtest" v-else>
                {{ navbar_en.back_test }}
              </a>
            </li>
            <li>
              <a href="/price" v-if="getLanguage == 'THAI'">
                {{ navbar_th.price }}
              </a>
              <a href="/price" v-else>
                {{ navbar_en.price }}
              </a>
            </li>
            <li>
              <a href="/reviews" v-if="getLanguage == 'THAI'">
                {{ navbar_th.reviews }}
              </a>
              <a href="/reviews" v-else>
                {{ navbar_en.reviews }}
              </a>
            </li>
            <li>
              <a href="/contact-us" v-if="getLanguage == 'THAI'">
                {{ navbar_th.contract }}
              </a>
              <a href="/contact-us" v-else>
                {{ navbar_en.contract }}
              </a>
            </li>
          </ul>
        </nav>
        <ul class="social-buttons">
          <li>
            <md-button
              href="https://www.facebook.com/autorebalance"
              target="_blank"
              class="md-twitter md-just-icon md-simple"
            >
              <i class="fab fa-facebook" />
            </md-button>
          </li>
          <li>
            <md-button
              href="http://line.me/ti/g/fqQ5X1GmYP"
              target="_blank"
              class="md-success md-just-icon md-simple"
            >
              <i class="fab fa-line" />
            </md-button>
          </li>
          <li>
            <md-button
              href="https://www.instagram.com/aiautorebalance/"
              target="_blank"
              class="md-danger md-just-icon md-simple"
            >
              <i class="fab fa-instagram" />
            </md-button>
          </li>
          <li>
            <md-button class="md-simple">
              <a style="font-size: 14px">
                <div class="">Copyright&copy; {{ year }}, 2022</div>
              </a>
            </md-button>
          </li>
        </ul>
      </div>
    </template>
  </footer>
</template>
<script>
import navbar_en from "../language/navbar_en";
import navbar_th from "../language/navbar_th";

import { mapGetters } from "vuex";

export default {
  props: {
    backgroundColor: String,
    type: String,
    size: String,
  },
  computed: mapGetters(["getLanguage"]),

  data() {
    return {
      year: new Date().getFullYear(),
      img1: require("@/assets/img/faces/card-profile6-square.jpg"),
      img2: require("@/assets/img/faces/christian.jpg"),
      img3: require("@/assets/img/faces/card-profile4-square.jpg"),
      img4: require("@/assets/img/faces/card-profile1-square.jpg"),
      img5: require("@/assets/img/faces/marc.jpg"),
      img6: require("@/assets/img/faces/kendall.jpg"),
      img7: require("@/assets/img/faces/card-profile5-square.jpg"),
      img8: require("@/assets/img/faces/card-profile2-square.jpg"),
      navbarLanguage: {},
      navbar_en,
      navbar_th,
    };
  },
  created() {
    if (this.getLanguage == "THAI") {
      this.navbarLanguage = navbar_th;
    } else if (this.getLanguage == "ENG") {
      this.navbarLanguage = navbar_en;
    } else {
      this.navbarLanguage = navbar_th;
    }
  },
};
</script>
<style></style>

module.exports = {
  home: "หน้าแรก",
  back_test: "ทดสอบย้อนหลัง",
  price: "ราคา",
  reviews: "รีวิว",
  knowledge: "เรียนรู้",
  auto_rebalance: "AUTO REBALANCE",
  strategies: "กลยุทธ์",
  new_blok: "บล็อคข่าว",
  faq: "คำถามที่พบบ่อย",
  document: "DOCUMENT",
  contract: "ติดต่อ",
  podcast: "พอดแคช",
};

module.exports = {
  home: "HOME",
  back_test: "BACK TEXT",
  price: "PRICE",
  reviews: "REVIEWS",
  knowledge: "KNOWLEDGE",
  auto_rebalance: "AUTO REBALANCE",
  strategies: "STRATEGIES",
  new_blok: "NEW BLOK",
  faq: "FAQ",
  document: "DOCUMENT",
  contract: "CONTRACT",
  podcast: "PODCAST",
};

<template>
  <div class="wrapper">
    <div class="section section-testimonials-1 header-filter background-blue">
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-100 mx-auto text-center"
          >
            <h1
              style="font-size: 30px; font-weight: 600; margin: 50px"
              class="text-orange"
            >
              <b
                v-if="getLanguage == 'THAI'"
                style="font-size: 3rem; font-weight: 800"
                >{{ th_title }}</b
              >
              <b v-else style="font-size: 3rem; font-weight: 800">{{
                en_title
              }}</b>
            </h1>
          </div>
        </div>

        <br />
        <br />

        <div class="md-layout">
          <div class="md-layout-item md-size-25 md-small-size-50">
            <a href="https://www.facebook.com/autorebalance" target="_blank">
              <img
                src="../../assets/img/social/facebook.webp"
                style="height: 75%"
              />
            </a>
          </div>

          <div class="md-layout-item md-size-25 md-small-size-50">
            <a href="http://line.me/ti/g/fqQ5X1GmYP" target="_blank">
              <img src="../../assets/img/social/line.png" style="height: 75%" />
            </a>
          </div>

          <div class="md-layout-item md-size-25 md-small-size-50">
            <a href="https://www.tiktok.com/@autorebalance.co" target="_blank">
              <img
                src="../../assets/img/social/tiktok.webp"
                style="height: 75%"
              />
            </a>
          </div>

          <div class="md-layout-item md-size-25 md-small-size-50">
            <a
              href="https://www.instagram.com/aiautorebalance/"
              target="_blank"
            >
              <img
                src="../../assets/img/social/instagram.png"
                style="height: 75%"
              />
            </a>
          </div>
        </div>

        <center>
          <h3 style="color: white" v-if="getLanguage == 'THAI'">
            {{ th_title_description }}
          </h3>
          <h3 style="color: white" v-else>{{ en_title_description }}</h3>

          <p
            v-if="getLanguage == 'THAI'"
            style="font-size: large; font-weight: 300; color: white"
          >
            {{ th_description }}
          </p>

          <p v-else style="font-size: large; font-weight: 300; color: white">
            {{ en_description }}
          </p>
        </center>

        <div class="md-layout" style="margin-top: 30px">
          <div class="md-layout-item md-size-50 md-small-size-100 ml-auto">
            <img
              src="../../assets/img/ARimg/LogoAR-02.svg"
              style="height: 75%"
            />
            <br />
            <br />
            <div style="color: white">
              <p>
                <a style="font-weight: 600; color: white">Email: </a>
                aiautorebalance@gmail.com
              </p>
              <p>
                <a style="font-weight: 600; color: white">Tel: </a> 089-1769303
              </p>
              <p>
                <a style="font-weight: 600; color: white">Open: </a> 24 ชั่วโมง
              </p>
            </div>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100 ml-auto">
            <form>
              <md-field style="color: white">
                <label>Name</label>

                <md-input style="color: white" v-model="name" type="text" />
                <a v-if="name == null">
                  <a v-if="getLanguage == 'THAI'" style="color: red">
                    {{ txtName }}
                  </a>

                  <a v-else style="color: red"> Please enter information </a>
                </a>
              </md-field>

              <md-field style="color: white">
                <label>Email</label>
                <md-input style="color: white" v-model="email" type="text" />
                <a v-if="email == null">
                  <a v-if="getLanguage == 'THAI'" style="color: red">
                    {{ txtName }}
                  </a>

                  <a v-else style="color: red"> Please enter information </a>
                </a>
              </md-field>

              <md-field>
                <label>Phone</label>
                <md-input v-model="phone" type="number" />
                <a v-if="phone == null">
                  <a v-if="getLanguage == 'THAI'" style="color: red">
                    {{ txtName }}
                  </a>

                  <a v-else style="color: red"> Please enter information </a>
                </a>
              </md-field>
              <md-field>
                <label>Your message</label>
                <md-textarea v-model="textarea" />
              </md-field>
              <div class="submit text-center mt-3">
                <md-button
                  v-if="getLanguage == 'THAI'"
                  style="color: red"
                  @click="notify()"
                  class="md-primary md-round"
                >
                  ติดต่อ
                </md-button>

                <md-button
                  v-else
                  style="color: red"
                  @click="notify()"
                  class="md-primary md-round"
                >
                  Contact Us
                </md-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <nav id="cd-vertical-nav" class="vertical-nav-active">
      <ul>
        <li>
          <a
            href="https://www.facebook.com/autorebalance"
            target="_blank"
            data-number="1"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #0f47f7"
                class="fab fa-facebook"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a
            href="http://line.me/ti/g/fqQ5X1GmYP"
            target="_blank"
            data-number="2"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #5afa33"
                class="fab fa-line"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a
            href="https://www.instagram.com/aiautorebalance/"
            target="_blank"
            data-number="3"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #fa33af"
                class="fab fa-instagram"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a
            href="https://medium.com/@aiautorebalance"
            target="_blank"
            data-number="4"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: white"
                class="fab fa-medium"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import axios from "axios";
import Swal from "sweetalert2";

import { mapGetters, mapActions } from "vuex";

import { API_URL } from "../../../config";

import {
  en_title,
  en_title_description,
  en_description,
} from "../../language/contract_en";
import {
  th_title,
  th_title_description,
  th_description,
} from "../../language/contract_th";

export default {
  components: {},
  computed: mapGetters(["getLanguage"]),

  bodyClass: "contact-us",
  mixins: [Mixins.InitMap],
  data() {
    return {
      name: null,
      email: null,
      phone: null,
      textarea: null,
      txtName: "กรุณาใส่ข้อมูล",
      en_title,
      en_title_description,
      en_description,
      th_title,
      th_title_description,
      th_description,
    };
  },
  methods: {
    notify() {
      if (this.name != null || this.email != null || this.phone != null) {
        axios
          .post(`${API_URL}/notify`, {
            name: this.name,
            email: this.email,
            phone: this.phone,
            textarea: this.textarea,
          })
          .then((response) => {
            this.name = null;
            this.email = null;
            this.phone = null;
            this.textarea = null;

            Swal.fire({
              title: `ส่งข้อมูลเรียบร้อย`,
              text: `รอการติดต่อกลับภายใน 48 ชม`,
              type: "success",
              confirmButtonClass: "btn btn-success",
              confirmButtonText: "ฉันเข้าใจและยอมรับ",

              buttonsStyling: true,
            });
          })
          .catch((error) => {
            // console.log("error00001", error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vertical-nav-active {
  display: block;
}
</style>

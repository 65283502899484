module.exports = {
  title_th: "บอทเทรด",
  title_2_th: "สำหรับนักเทรดมืออาชีพ",
  description_1_th: "เป็นหนึ่งในบอทเทรด ",
  description_2_th:
    "มีกลยุทธ์ที่สร้าง Cash Flow ที่มีประสิทธิภาพสูง และด้วยกลยุทธ์การคำนวณทางคณิตศาตร์ Math Algorithm ที่ผ่านการวิเคราะห์ด้วย ทีมนักพัฒนา อย่างต่อเนื่อง",

  th_title_why_autorebalance: "ทำไมต้อง",
  th_description_why_autorebalance:
    "ทางออกเดียวที่จะให้คุณได้ใช้ชีวิตอย่างเต็มที่ พร้อมได้รับกำไรจากการเทรดคริปโต ทำกำไรจากการลงทุนได้แบบไม่ต้องเครียด ไม่ต้องอดนอน ไม่ต้องเฝ้าจอ",
  th_analysis: "การวิเคราะห์",
  th_analysis_description:
    "การวิเคราะห์ เป็นกลยุทธ์ที่เหมาะสมกับตลาดที่มีความผันผวนสูง ตลอดระยะเวลา 7 ปี มีผลลัพธ์ที่ดี เมื่อเทียบกับตลาดอื่นๆ",

  th_combined_Platform: "รวมแพลตฟอร์ม",
  th_combined_Platform_description:
    "แพลตฟอร์มที่ผสานกับการแลกเปลี่ยนชั้นนำทั่วโลก รวดเร็ว สภาพคล่องสูง และใช้งานง่าย",
  th_trading_AR: "ซื้อขาย AR",
  th_trading_AR_description:
    "อัลกอริธึมที่ปรับสมดุลพอร์ตในระยะสั้นและระยะยาว สำหรับนักลงทุนมืออาชีพ",
  th_efficiency: "ประสิทธิภาพ",
  th_efficiency_description:
    "กลยุทธ์บริหารความเสี่ยงที่ช่วยเพิ่มประสิทธิภาพให้กับพอร์ตลงทุนในตลาดคริปโตเคอเรนซี่",
  th_safety: "ความปลอดภัย",
  th_safety_description: "กระบวนการการจัดการพอร์ตที่ใช้เทคโนโลยีที่ปลอดภัยสูง",
  th_service: "การบริการ",
  th_service_description:
    "คุณภาพในการให้บริการข้อมูล ความรู้เชิงลึก และฝ่ายสนับสนุนตลอด 24ชม.",

  /////
  th_management_strategy:
    " Auto Rebalance กลยุทธ์บริหารความเสี่ยงของนักเทรดมืออาชีพ",
  th_description_1:
    "เป็นเครื่องมือที่ใช้ในการเข้าสู่ตลาดคริปโตเคอเรนซี่ที่ง่ายและบริหารความเสี่ยงให้ต่ำที่สุด",
  th_description_2:
    "เป็นกลยุทธ์ที่ดีในตลาดที่มีความผันผวนสูงอย่างคริปโตเคอเรนซี่",
  th_description_3:
    "ทราบถึงข้อดีข้อเสีย เพื่อเลือกใช้ในเหมาะสมกับ ทุน ระยะเวลาความผันผวน ความเสี่ยง ความเข้าใจใน circle ของตลาดคริปโตเคอเรนซี่",
  th_description_4:
    "บอทเทรดที่มีการ training ก่อนการใช้งานจริงทั้งออนไลน์และออฟไลน์ อีกทั้งสามารถเรียนซ้ำและมีทีมงานอัพเดทข่าวสาร ตลอดอายุสัญญา",
  th_description_5:
    "มีความรู้ความเข้าใจการใช้ api และความปลอดภัยในตัวบุคคลเพื่อลดความเสี่ยงในการลงทุน",
  th_description_6:
    " มีทีมงานโปรแกรมเมอร์ ฝ่ายซัพพอร์ท ให้บริการตลอด 24 ชม. พร้อม standby ดูแลตลอดอายุสัญญาให้บริการ",
  th_description_7:
    "ตั้งค่าการใช้งานผู้ใช้งานสามารถตั้งค่าการใช้งานและออกแบบพอร์ตเทรดผ่านทีมงานได้เองเหมือนเขียนบอทเทรดเอง เลยทีเดียว",
  th_reviews: "รีวิว",
  th_strategy: "กลยุทธ์",
};

import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";

import Carousel3d from "vue-carousel-3d";

import MaterialKit from "./plugins/material-kit";

import store from "./store";

Vue.config.productionTip = false;

Vue.use(MaterialKit);

Vue.use(Carousel3d);

new Vue({
  router,
  store,

  render: (h) => h(App),
}).$mount("#app");

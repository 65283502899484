<template>
  <div class="wrapper">
    <div id="main-panel" class="main">
      <!-- Headers Section -->
      <div id="headers" class="cd-section">
        <headers />
      </div>

      <!-- Features Section -->
      <div id="features" class="cd-section">
        <features />
      </div>

      <!-- Blogs Section -->
      <div id="blogs" class="cd-section">
        <blogs />
      </div>

      <!-- Testimonials Section -->
      <div id="testimonials" class="cd-section">
        <testimonials />
      </div>

      <div id="strategy" class="cd-section">
        <strategy />
      </div>

      <div id="supports" class="cd-section">
        <supports />
      </div>
    </div>
    <nav id="cd-vertical-nav" class="vertical-nav-active">
      <ul>
        <li>
          <a
            href="https://www.facebook.com/autorebalance"
            target="_blank"
            data-number="1"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #0f47f7"
                class="fab fa-facebook"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a
            href="http://line.me/ti/g/fqQ5X1GmYP"
            target="_blank"
            data-number="2"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #5afa33"
                class="fab fa-line"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a
            href="https://www.instagram.com/aiautorebalance/"
            target="_blank"
            data-number="3"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #fa33af"
                class="fab fa-instagram"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a
            href="https://medium.com/@aiautorebalance"
            target="_blank"
            data-number="4"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: white"
                class="fab fa-medium"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>
      </ul>

      <!-- <ul>
        <li>
          <a
            href="javascript:void(0)"
            data-number="1"
            @click="scrollToElement('headers')"
          >
            <span class="cd-dot" />
            <span class="cd-label">Auto Rebalance</span>
          </a>
        </li>
        <li>
          <a
            href="javascript:void(0)"
            data-number="2"
            @click="scrollToElement('features')"
          >
            <span class="cd-dot" />
            <span class="cd-label">Why Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a
            href="javascript:void(0)"
            data-number="5"
            @click="scrollToElement('blogs')"
          >
            <span class="cd-dot" />
            <span class="cd-label">Blogs</span>
          </a>
        </li>

        <li>
          <a
            href="javascript:void(0)"
            data-number="4"
            @click="scrollToElement('testimonials')"
          >
            <span class="cd-dot" />
            <span class="cd-label">Review</span>
          </a>
        </li>

        <li>
          <a
            href="javascript:void(0)"
            data-number="6"
            @click="scrollToElement('supports')"
          >
            <span class="cd-dot" />
            <span class="cd-label">STRATEGY </span>
          </a>
        </li>
      </ul> -->
    </nav>
  </div>
</template>

<script>
import Headers from "./sections/Headers";
import Features from "./sections/Features";
import Blogs from "./sections/Blogs";
import Teams from "./sections/Teams";
import Projects from "./sections/Projects";
import Pricing from "./sections/Pricing";
import Testimonials from "./sections/Testimonials";
import ContactUs from "./sections/ContactUs";
import Supports from "./sections/Supports";
import Social from "./sections/Social";

import Mixins from "@/plugins/basicMixins";
import Swal from "sweetalert2";
import Strategy from "./sections/Strategy.vue";

import { mapGetters, mapActions } from "vuex";

import warning_en from "../language/warning_en";
import warning_th from "../language/warning_th";

export default {
  components: {
    Headers,
    Features,
    Blogs,
    Testimonials,
    Supports,
    Strategy,
  },
  mixins: [Mixins.VerticalNav],
  computed: mapGetters(["getLanguage"]),
  data() {
    return {
      warning: {},
    };
  },

  methods: {},
  created() {
    if (this.getLanguage == "THAI") {
      this.warning = warning_th;
    } else {
      this.warning = warning_en;
    }
    setTimeout(async () => {
      Swal.fire({
        title: this.warning.title,
        text: this.warning.description,
        type: "success",
        confirmButtonClass: "btn btn-success",
        confirmButtonText: this.warning.button_text,
        buttonsStyling: true,
      });
    }, 500);
  },
};
</script>

<style lang="scss">
.vertical-nav-active {
  display: block;
}

@media all and (max-width: 768px) {
  // .vertical-nav-active {
  //   display: none;
  // }
}
</style>

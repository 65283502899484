<template>
  <div class="wrapper">
    <div class="section background-blue" style="color: white">
      <br />
      <br />
      <div class="container">
        <center>
          <h2 v-if="getLanguage == 'THAI'" style="font-weight: 600">
            <b class="text-orange" style="font-size: 40px; font-weight: 800"
              >คำถามที่พบบ่อย</b
            >
          </h2>
          <h2 v-else style="font-weight: 600">
            <b class="text-orange" style="font-size: 40px; font-weight: 800"
              >FAQ</b
            >
          </h2>
        </center>

        <br />

        <div class="md-layout" v-if="getLanguage == 'THAI'">
          <div class="md-layout-item md-size-100 md-small-size-100">
            <collapse
              :active-tab="1"
              :collapse="[
                'Auto Rebalance คืออะไร ?',
                'Auto Rebalance มีมาเพื่ออะไร ?',
                'การทำงานของ Auto Rebalance ?',
                ' ความเสี่ยงของ Auto Rebalance คืออะไร ?',
                'Auto Rebalance ใช้บนแพลตฟอร์มไหนได้บ้าง ?',
                'ขั้นต่ำในการลงทุน ?',
                'ระยะเวลาในการให้บริการ ?',
                'ค่าบริการเป็นอย่างไร?',
                'ผลตอบแทนเป็นอย่างไร?',
              ]"
              icon="keyboard_arrow_down"
              color-collapse="success"
            >
              <template slot="md-collapse-pane-1">
                <p>
                  Auto Rebalance (AR) เป็นหนึ่งในบอทเทรด Rebalance
                  มีกลยุทธ์ที่สร้าง Cash Flow ที่มีประสิทธิภาพสูง
                  และด้วยกลยุทธ์การคำนวณทางคณิตศาตร์ Math Algorithm
                  ที่ผ่านการวิเคราะห์ด้วย ทีมนักพัฒนา อย่างต่อเนื่อง
                  โดยหลักการทำงานของ AutoRebalance นั้นง่ายมาก
                  โดยบอทจะทำการแบ่งสินทรัพย์ออกเป็น 2 ส่วน
                  ที่มีมูลค่าแตกต่างกันให้มีมูลค่าที่สมดุลกัน
                  โดยอาศัยความผันผวนของราคาในตลาด
                  แต่ในขณะเดียวกันเราไม่จำเป็นต้องกังวลราคาของตลาดที่มีความผันผวนเลยก็ได้
                  เพราะระบบของ AR จะเริ่มทำการสร้าง “สมดุล” ให้กับพอร์ต
                  โดยการโอนถ่ายมูลค่าสินทรัพย์ ที่มีอยู่ไปมา ขายส่วนที่เกิน
                  ไปซื้อส่วนที่ขาด เกิดเป็นกระบวนการ “ขายแพง ซื้อถูก”
                  วนไปมาตลอดเวลา เราเรียกว่า Cash Flow ยิ่งราคาผันผวน
                  ยิ่งเกิดกระบวนการ Auto Rebalance บ่อยขึ้น จนเกิด Capital Gain
                  นั่นเอง
                </p>
              </template>
              <template slot="md-collapse-pane-2">
                <p>
                  เพื่อบริหารความเสี่ยงของพอร์ต จากความผันผวนในตลาด
                  ในขณะเดียวกัน การทำ Auto Rebalance บ่อย อย่างต่อเนื่อง
                  ยิ่งราคาผันผวน ยิ่งเกิดกระบวนการ Auto Rebalance บ่อยขึ้น
                  จนเกิด Capital Gain นั่นเอง
                </p>
              </template>
              <template slot="md-collapse-pane-3">
                <p>
                  Auto Rebalance คือ
                  วิธีการสร้างสภาพคล่องในการลงทุนที่อาจจะมีสภาวะที่ผันผวน ซึ่ง
                  โดยทั่วไปของการลงทุนก็มักจะเจอความเสี่ยงกันอยู่แล้ว
                  แต่เราจะสร้างสภาพคล่องให้กับการลงทุนของเราได้อย่างไร
                  วันนี้ขอนำเสนอ Auto Rebalance ครับ Auto Rebalance
                  เป็นกลยุทธ์ที่สร้าง clashflow
                  ให้เราค่อนข้างสูงเมื่อเราเผชิญกับตลาดขาลงอย่างหนัก
                  เพราะเราจะถือสินทรัพย์ไว้ 50% และถือ เงินสดไว้อีก 50%
                  ดังนั้นเมื่อตลาดลงหนักสินทรัพย์มูลค่าก็จะลดลงตามแต่เราจะมีเงินสดที่ยังมีมูลค่าคงที่
                  (stable coin)
                  คอยนำเงินสดบางส่วนมาซื้อสินทรัพย์เพิ่มทำให้เราจะได้สินทรัพย์ในราคาที่ถูกกว่าเดิม
                  เมื่อเรามีปริมาณสินทรัพย์เพิ่มขึ้น และ เงินสดยังคงเหลือเยอะ
                  ทำให้เมื่อราคาขึ้นกลับมา ทำให้เกิด capital gain
                  เราจะได้ส่วนต่างของราคาส่วนต่างจากสินทรัพย์ที่เราซื้อตอนถูกเอาไว้นั่นเอง
                  สรุปคือ ตอนตลาดลงเราจะขาดทุนน้อยเพราะถือสินทรัพย์ไว้แค่ 50%
                  และเราจะส่วนต่างของสินทรัพย์และเงินสด
                  เพิ่มขึ้นจากเดิมเมื่อราคากลับขึ้นมา
                  และยิ่งใช้เวลานานขึ้นการเกิด capital gain ก็จะยิ่งมากขึ้นครับ
                </p>
              </template>

              <template slot="md-collapse-pane-4">
                <p>
                  ความเสี่ยง
                  <br />
                  <br />
                  - Exchange ปิด หรือ มีการเปลี่ยนแปลงเงื่อนไขการให้บริการ Api
                  <br />
                  - การที่เหรียญที่เลือกโดน Delist ออกจาก Exchange
                  <br />
                  - การเลือกเหรียญที่ไม่มีความผันผวนของราคามากพอ
                  ซึ่งผลลัพธ์ขึ้นอยู่กับราคาหรือสภาพตลาด
                  และปัจจัยอื่นๆที่ไม่สามารถควบคุมได้
                  ซึ่งเป็นเรื่องของอนาคตที่ไม่สามารถคาดการณ์ได้
                  <br />
                  - ทุนไม่สอดคล้องกับสภาพตลาดก็อาจก่อให้เกิด การรอ ออเดอร์นานๆ
                  ทำให้เสียโอกาส
                </p>
              </template>

              <template slot="md-collapse-pane-5">
                <p>ใช้ได้กับทุกแพลตฟอร์ม ที่อนุญาตให้เทรดด้วยการเชื่อม Api</p>
              </template>

              <template slot="md-collapse-pane-6">
                <p>ในส่วนของทุนเริ่มต้นที่ $500</p>
              </template>

              <template slot="md-collapse-pane-7">
                <p>ระยะการให้บริการ 1 ปี ตามที่ระบุในข้อตกลงการใช้บริการ</p>
              </template>

              <template slot="md-collapse-pane-8">
                <p>
                  โปรโมชั่นพิเศษ‼️ ค่าบริการบอทเทรด จำนวนจำกัด 10 ท่านเท่านั้น
                  <br />
                  <br />
                  🔥 ค่าบริการบอทเทรด เริ่มต้นแค่วันละ 46 บาท✨ เท่านั้น ‼️
                  <br />
                  <br />
                  🕹1port 2 คู่ เทรด 500 usdt ต่อปี ไม่ซ้ำเหรียญเทรด จากเดิม
                  1port 1 คู่ เทรด 500 usdt ต่อปี ไม่ซ้ำเหรียญเทรด
                  <br />
                  🕹1port 3 คู่ เทรด 750 usdt ต่อปี ไม่ซ้ำเหรียญเทรด จากเดิม
                  1port 2 คู่ เทรด 750 usdt ต่อปี ไม่ซ้ำเหรียญเทรด Fusion
                  Rebalance <br />🕹1portไม่อั้น คู่เทรด และไม่ซ้ำเหรียญเทรด 1000
                  usdt ต่อปี
                </p>
              </template>

              <template slot="md-collapse-pane-9">
                <p>
                  ตัวอย่างจากพอร์ตจริง
                  <br />
                  <br />
                  ใช้กลยุทธ์ Auto Rebalance คู่เทรด Bitcoin กับ Etherium
                  ในวันที่ 10 ธันวาคม 2020 โดยแบ่ง 1 บิทคอยน์ Auto Rebalance
                  Etherium 16.3536 ETH Bitcoin 0.49976 BTC ผ่านมาจนถึงวันนี้จะมี
                  Etherium 10.71347 ETH Bitcoin 0.85239 BTC Total Value (BTC)
                  1.7 BTC จาก 1 BTC เป็น 1.7 BTC หมายเหตุ ข้อมูล ณ วันที่
                  17/8/22
                  <br />
                  <br />
                  ทั้งนี้การใช้บริการดังกล่าวมีความเสี่ยง ไม่สามารถการันตี
                  ผลตอบแทนในอนาคตได้ การใช้งานในสินทรัพย์ดิจิทัลมีความเสี่ยงสูง
                  อาจสูญเสียเงินทุน ผู้ใช้งานควรทำความเข้าใจและศึกษาข้อมูล
                  รวมทั้งใช้ทุนให้เหมาะสมกับความเสี่ยงที่ยอมรับได้”
                </p>
              </template>
            </collapse>
          </div>
        </div>

        <div class="md-layout" v-else>
          <div class="md-layout-item md-size-100 md-small-size-100">
            <collapse
              :active-tab="1"
              :collapse="[
                'What is Auto Rebalance ?',
                'What is Auto Rebalance for ?',
                'How does Auto Rebalance work ?',
                'What is the risk of Auto Rebalance ?',
                'What platforms can Auto Rebalance be used on ?',
                'Minimum investment ?',
                'Service period ?',
                'What are the service fees ?',
                'How is the payout ?',
              ]"
              icon="keyboard_arrow_down"
              color-collapse="success"
            >
              <template slot="md-collapse-pane-1">
                <p>
                  Auto Rebalance (AR) is one of the Rebalance bots, which has a
                  strategy that generates highly efficient cash flow. And with
                  Math Algorithm mathematical computation strategies that are
                  continuously analyzed by the developer team by the working
                  principle of AutoRebalance is very simple: the bot divides an
                  asset into two parts with different values ​​to have a
                  balanced value based on the volatility of market prices But at
                  the same time, we don't need to worry about market price
                  fluctuations at all, because AR's system will begin to
                  "balance" the portfolio by transferring asset values existing
                  back and forth sell excess go buy the missing part There is a
                  process of "sell high, buy cheap" all the time, we call it
                  Cash Flow, the more the price fluctuates. The more the Auto
                  Rebalance process occurs, the more capital gains are formed.
                </p>
              </template>
              <template slot="md-collapse-pane-2">
                <p>
                  To manage portfolio risks from market volatility at the same
                  time, doing frequent Auto Rebalance continuously. The more the
                  price fluctuates the more Auto Rebalance process occurs, the
                  more capital gains are formed.
                </p>
              </template>
              <template slot="md-collapse-pane-3">
                <p>
                  Auto Rebalance is a method to create liquidity in investments
                  that may be subject to volatile conditions, in which
                  investments are generally exposed to risks. But how do we
                  create liquidity for our investments? Today I would like to
                  present Auto Rebalance. Auto Rebalance is a strategy that
                  generates quite high clashflow for us when we face a heavy
                  down market. Because we will hold 50% of the asset and the
                  other 50% in cash, so when the market is heavy, the asset's
                  value will decrease as we have a stable coin to bring some
                  cash to buy the asset. This will allow us to get assets at a
                  lower price than before. When we have more assets and there is
                  still a lot of cash left. Therefore, when the price rises
                  back, causing capital gain, we will get the difference in the
                  price difference from the asset that we bought when it was
                  cheap enough. In conclusion, when the market is down, we will
                  lose less because we hold only 50% of the asset and we will
                  have the difference assets and cash increased from the
                  original when the price returned up And the longer it takes,
                  the more capital gains will be.
                </p>
              </template>

              <template slot="md-collapse-pane-4">
                <p>
                  Risks
                  <br />
                  <br />
                  - Exchange is closed or Api terms of service have been
                  changed.
                  <br />
                  - Delisting of the selected coin from the exchange
                  <br />
                  - Selection of coins that do not have enough price
                  fluctuations The result depends on the price or market
                  conditions. and other uncontrollable factors which is about
                  the future that cannot be predicted
                  <br />
                  - Capital is not in accordance with market conditions, it may
                  cause a long wait for orders, resulting in lost opportunities.
                </p>
              </template>

              <template slot="md-collapse-pane-5">
                <p>
                  Compatible with all platforms that allows trading with API
                  connection
                </p>
              </template>

              <template slot="md-collapse-pane-6">
                <p>As for the capital, starting at $500</p>
              </template>

              <template slot="md-collapse-pane-7">
                <p>
                  Services last for 1 year as specified in the service
                  agreement.
                </p>
              </template>

              <template slot="md-collapse-pane-8">
                <p>
                  Special promotion ‼️ The service fees for trading bots is
                  limited to 10 people only.

                  <br />
                  <br />
                  🔥 Trading bot service fee Starting from only 46 baht per day
                  ✨ only!!️ 🔥
                  <br />
                  <br />
                  🕹 1port 2 pairs traded 500 USDT per year, no duplicate coins
                  traded from the original 1port 1 pair, traded 500 usdt per
                  year, no duplicate coins traded.
                  <br />
                  🕹 1port 3 pairs, trade 750 usdt per year, unique coins traded
                  from the original 1port 2 pairs, trade 750 usdt per year,
                  unique coins, Fusion Rebalance
                  <br />🕹 Unlimited 1 port, trading pairs and no duplicate coins
                  trading 1000 usdt per year.
                </p>
              </template>

              <template slot="md-collapse-pane-9">
                <p>
                  Real port example
                  <br />
                  <br />
                  Using Auto Rebalance Strategy, Bitcoin and Ethereum trading
                  pair on December 10, 2020 divided by 1 Bitcoin Auto Rebalance
                  Etherium 16.3536 ETH Bitcoin 0.49976 BTC Until today there is
                  Etherium 10.71347 ETH Bitcoin 0.85239 BTC Total Value (BTC)
                  1.7 BTC from 1 BTC is 1.7 BTC Note data as of 17/8/22
                  <br />
                  <br />
                  However, the use of such services is at risk. cannot guarantee
                  returns in the future Its use in digital assets carries a high
                  level of risk. may lose capital Users should understand and
                  study the information. and use capital appropriately with
                  acceptable risks.”
                </p>
              </template>
            </collapse>
          </div>
        </div>
      </div>
    </div>
    <nav id="cd-vertical-nav" class="vertical-nav-active">
      <ul>
        <li>
          <a
            href="https://www.facebook.com/autorebalance"
            target="_blank"
            data-number="1"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #0f47f7"
                class="fab fa-facebook"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a
            href="http://line.me/ti/g/fqQ5X1GmYP"
            target="_blank"
            data-number="2"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #5afa33"
                class="fab fa-line"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a
            href="https://www.instagram.com/aiautorebalance/"
            target="_blank"
            data-number="3"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #fa33af"
                class="fab fa-instagram"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a
            href="https://medium.com/@aiautorebalance"
            target="_blank"
            data-number="4"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: white"
                class="fab fa-medium"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { Collapse } from "@/components";

import { mapGetters } from "vuex";

export default {
  name: "Reviews",
  components: { Collapse },
  computed: mapGetters(["getLanguage"]),

  data() {
    return {
      testimonials1: require("@/assets/img/dg2.jpg"),
      cardBlogPost: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2022/02/S__5226628.jpg",
      },
      cardBlogPost2: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2021/03/1_A1x1XR7PJEhZiSqSNWxvvw.png",
      },
      cardBlogPost3: {
        cardBlogPost1:
          "https://scontent.fbkk2-4.fna.fbcdn.net/v/t39.30808-6/274545843_493958235592183_8891905078477806124_n.jpg?_nc_cat=101&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeFtwmjIM0s5aKuP3FP_EKWE9KvFs1u-Bsj0q8WzW74GyHof3DbJchYdAFLJSwOAt-0jBuSrShuHTLMHvmqHAnGz&_nc_ohc=Rc2aeFz0ZeEAX8GMXjR&tn=j0WnZqtCr_hzpKka&_nc_ht=scontent.fbkk2-4.fna&oh=00_AT_hRjG8shrQy4ER-lJWkKeH9omKC4oRT-YFgJqbFjAuog&oe=622664C0",
      },
    };
  },
  methods: {
    sectionTestimonials(image) {
      return {
        backgroundImage: `url(${image})`,
      };
    },
  },
};
</script>

<style lang="scss">
.vertical-nav-active {
  display: block;
}
</style>

<template>
  <div class="wrapper">
    <!-- Features 1 -->

    <div
      class="section section-features-1"
      style="background-color: #001a26; color: white"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <div v-if="getLanguage == 'THAI'">
              <h2 class="text-title">
                {{ th_title_why_autorebalance }}
                <b style="font-size: 40px; font-weight: 800">Auto Rebalance</b>
              </h2>
              <h5 style="font-size: 20px; font-weight: 400">
                {{ th_description_why_autorebalance }}
              </h5>
            </div>
            <div v-else>
              <h2 class="text-title">
                {{ en_title_why_autorebalance }}
                <b style="font-size: 40px; font-weight: 800">Auto Rebalance</b>
              </h2>
              <h5 style="font-size: 20px; font-weight: 400">
                {{ en_description_why_autorebalance }}
              </h5>
            </div>
          </div>
        </div>
        <div class="md-layout">
          <div
            class="md-layout-item md-size-33 md-small-size-100"
            style="
              display: block;
              justify-content: center;
              text-align: center;
              padding: 50px;
              margin: 0 auto;
              max-width: 360px;
            "
          >
            <img
              src="../../assets/img/ARimg/webrebalance-23.svg"
              style="width: 20%"
            />
            <div v-if="getLanguage == 'THAI'">
              <h4 slot="title" style="font-weight: 600">{{ th_analysis }}</h4>
              <p slot="content" style="color: white">
                {{ th_analysis_description }}
              </p>
            </div>
            <div v-else>
              <h4 slot="title" style="font-weight: 600">{{ en_analysis }}</h4>
              <p slot="content" style="color: white">
                {{ en_analysis_description }}
              </p>
            </div>
          </div>
          <div
            class="md-layout-item md-size-33 md-small-size-100"
            style="
              display: block;
              justify-content: center;
              text-align: center;
              padding: 50px;
              margin: 0 auto;
              max-width: 360px;
            "
          >
            <img
              src="../../assets/img/ARimg/webrebalance-22.svg"
              style="width: 20%"
            />
            <div v-if="getLanguage == 'THAI'">
              <h4 slot="title" style="font-weight: 600">
                {{ th_combined_Platform }}
              </h4>
              <p slot="content" style="color: white">
                {{ th_combined_Platform_description }}
              </p>
            </div>
            <div v-else>
              <h4 slot="title" style="font-weight: 600">
                {{ en_combined_Platform }}
              </h4>
              <p slot="content" style="color: white">
                {{ en_combined_Platform_description }}
              </p>
            </div>
          </div>
          <div
            class="md-layout-item md-size-33 md-small-size-100"
            style="
              display: block;
              justify-content: center;
              text-align: center;
              padding: 50px;
              margin: 0 auto;
              max-width: 360px;
            "
          >
            <img
              src="../../assets/img/ARimg/webrebalance-21.svg"
              style="width: 20%"
            />

            <div v-if="getLanguage == 'THAI'">
              <h4 slot="title" style="font-weight: 600">{{ th_trading_AR }}</h4>
              <p slot="content" style="color: white">
                {{ th_trading_AR_description }}
              </p>
            </div>
            <div v-else>
              <h4 slot="title" style="font-weight: 600">{{ en_trading_AR }}</h4>
              <p slot="content" style="color: white">
                {{ en_trading_AR_description }}
              </p>
            </div>
          </div>
          <div
            class="md-layout-item md-size-33 md-small-size-100"
            style="
              display: block;
              justify-content: center;
              text-align: center;
              padding: 50px;
              margin: 0 auto;
              max-width: 360px;
            "
          >
            <img
              src="../../assets/img/ARimg/webrebalance-19.svg"
              style="width: 20%"
            />

            <div v-if="getLanguage == 'THAI'">
              <h4 slot="title" style="font-weight: 600">{{ th_efficiency }}</h4>
              <p slot="content" style="color: white">
                {{ th_efficiency_description }}
              </p>
            </div>
            <div v-else>
              <h4 slot="title" style="font-weight: 600">{{ en_efficiency }}</h4>
              <p slot="content" style="color: white">
                {{ en_efficiency_description }}
              </p>
            </div>
          </div>
          <div
            class="md-layout-item md-size-33 md-small-size-100"
            style="
              display: block;
              justify-content: center;
              text-align: center;
              padding: 50px;
              margin: 0 auto;
              max-width: 360px;
            "
          >
            <img
              src="../../assets/img/ARimg/webrebalance-20.svg"
              style="width: 20%"
            />

            <div v-if="getLanguage == 'THAI'">
              <h4 slot="title" style="font-weight: 600">{{ th_safety }}</h4>
              <p slot="content" style="color: white">
                {{ th_safety_description }}
              </p>
            </div>
            <div v-else>
              <h4 slot="title" style="font-weight: 600">{{ en_safety }}</h4>
              <p slot="content" style="color: white">
                {{ en_safety_description }}
              </p>
            </div>
          </div>
          <div
            class="md-layout-item md-size-33 md-small-size-100"
            style="
              display: block;
              justify-content: center;
              text-align: center;
              padding: 50px;
              margin: 0 auto;
              max-width: 360px;
            "
          >
            <img
              src="../../assets/img/ARimg/webrebalance.svg"
              style="width: 20%"
            />

            <div v-if="getLanguage == 'THAI'">
              <h4 slot="title" style="font-weight: 600">{{ th_service }}</h4>
              <p slot="content" style="color: white">
                {{ th_service_description }}
              </p>
            </div>
            <div v-else>
              <h4 slot="title" style="font-weight: 600">{{ en_service }}</h4>
              <p slot="content" style="color: white">
                {{ en_service_description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end Features 1 -->

    <!-- Features 3 -->
    <div
      class="section section-features-3"
      style="background-color: #001a26; color: white"
    >
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-size-100 boxjump">
            <!-- <div class="phone-container">
              <img :src="iphone" />
            </div> -->
            <center>
              <img src="../../assets/img/ARimg/MascotAR-02.png" width="80%" />
            </center>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            <div>
              <div v-if="getLanguage == 'THAI'">
                <h2 style="font-weight: 600">
                  {{ th_management_strategy }}
                </h2>
              </div>
              <div v-else>
                <h2 style="font-weight: 600">
                  {{ en_management_strategy }}
                </h2>
              </div>

              <info-areas>
                <!-- <h4 slot="title" style="font-weight:400;">ช่วยปกป้องความเสี่ยง</h4> -->
                <div
                  v-if="getLanguage == 'THAI'"
                  slot="content"
                  style="line-height: 2"
                >
                  <li style="font-size: 16px; color: white" class="description">
                    {{ th_description_1 }}
                  </li>
                  <li style="font-size: 16px; color: white" class="description">
                    {{ th_description_2 }}
                  </li>
                  <li style="font-size: 16px; color: white" class="description">
                    {{ th_description_3 }}
                  </li>

                  <li style="font-size: 16px; color: white" class="description">
                    {{ th_description_4 }}
                  </li>

                  <li style="font-size: 16px; color: white" class="description">
                    {{ th_description_5 }}
                  </li>

                  <li style="font-size: 16px; color: white" class="description">
                    {{ th_description_6 }}
                  </li>

                  <li style="font-size: 16px; color: white" class="description">
                    {{ th_description_7 }}
                  </li>
                </div>

                <div v-else slot="content" style="line-height: 2">
                  <li style="font-size: 16px; color: white" class="description">
                    {{ en_description_1 }}
                  </li>
                  <li style="font-size: 16px; color: white" class="description">
                    {{ en_description_2 }}
                  </li>
                  <li style="font-size: 16px; color: white" class="description">
                    {{ en_description_3 }}
                  </li>

                  <li style="font-size: 16px; color: white" class="description">
                    {{ en_description_4 }}
                  </li>

                  <li style="font-size: 16px; color: white" class="description">
                    {{ en_description_5 }}
                  </li>

                  <li style="font-size: 16px; color: white" class="description">
                    {{ en_description_6 }}
                  </li>

                  <li style="font-size: 16px; color: white" class="description">
                    {{ en_description_7 }}
                  </li>
                </div>
              </info-areas>
              <!-- <info-areas>
                <h4 slot="title" style="font-weight:400;">มีเวลาน้อย</h4>
                <div slot="content" class="description">
                  <p style="font-size: 16px; color: white;">
                    ช่วยคนเก๋าเกม ศึกษาข้อมูลมาอย่างดี แต่ดันมีเวลาน้อย
                    ติดทำงานประจำ แถมยังต้องการพักผ่อน
                    ไหนจะเรื่องอารมณ์ที่เข้ามามีส่วนในการตัดสินใจ ความโลภ
                    เสียดาย กลัวเจ๊ง จนทำให้ลงทุนไม่เจ๋งเหมือนอย่างที่เรียนมา
                  </p>
                </div>
              </info-areas>
              <info-areas>
                <h4 slot="title" style="font-weight:400;">เปิดตลอด 24 ชม.</h4>
                <div slot="content" class="description">
                  <p style="font-size: 16px; color: white;">
                    ตลาดคริปโตเปิดตลอด 24 ชม. ไม่มีปิดตลาดเหมือนกับหุ้น
                    ไม่มีเพดานราคา เปิดขายให้กับนักเทรดทั่วโลก
                    ตัวสินทรัพย์ขึ้นได้ 1000% ก็ลงได้ 1000% ในเวลาสั้น ๆ
                    ถ้าไม่เฝ้าตลอด อาจพลาดโอกาสทำกำไร
                    หรืออาจตกรถซื้อเหรียญไม่ทัน
                  </p>
                </div>
              </info-areas> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end Features 5 -->
  </div>
</template>

<script>
import { InfoAreas } from "@/components";
import { mapGetters } from "vuex";
import {
  en_analysis,
  en_analysis_description,
  en_combined_Platform,
  en_combined_Platform_description,
  en_trading_AR,
  en_trading_AR_description,
  en_efficiency,
  en_efficiency_description,
  en_safety,
  en_safety_description,
  en_service,
  en_service_description,
  en_title_why_autorebalance,
  en_description_why_autorebalance,
  en_management_strategy,
  en_description_1,
  en_description_2,
  en_description_3,
  en_description_4,
  en_description_5,
  en_description_6,
  en_description_7,
} from "../../language/home_en";

import {
  th_analysis,
  th_analysis_description,
  th_combined_Platform,
  th_combined_Platform_description,
  th_trading_AR,
  th_trading_AR_description,
  th_efficiency,
  th_efficiency_description,
  th_safety,
  th_safety_description,
  th_service,
  th_service_description,
  th_title_why_autorebalance,
  th_description_why_autorebalance,
  th_management_strategy,
  th_description_1,
  th_description_2,
  th_description_3,
  th_description_4,
  th_description_5,
  th_description_6,
  th_description_7,
} from "../../language/home_th";

export default {
  name: "Features",
  components: {
    InfoAreas,
  },
  computed: mapGetters(["getLanguage"]),

  data() {
    return {
      iphone: require("@/assets/img/sections/iphone.jpg"),
      iphone2: require("@/assets/img/sections/iphone2.png"),
      image: require("@/assets/img/bg9.jpg"),
      th_analysis,
      th_analysis_description,
      th_combined_Platform,
      th_combined_Platform_description,
      th_trading_AR,
      th_trading_AR_description,
      th_efficiency,
      th_efficiency_description,
      th_safety,
      th_safety_description,
      th_service,
      th_service_description,
      en_analysis,
      en_analysis_description,
      en_combined_Platform,
      en_combined_Platform_description,
      en_trading_AR,
      en_trading_AR_description,
      en_efficiency,
      en_efficiency_description,
      en_safety,
      en_safety_description,
      en_service,
      en_service_description,
      en_title_why_autorebalance,
      en_description_why_autorebalance,
      th_title_why_autorebalance,
      th_description_why_autorebalance,
      th_management_strategy,
      th_description_1,
      th_description_2,
      th_description_3,
      th_description_4,
      th_description_5,
      th_description_6,
      th_description_7,
      en_management_strategy,
      en_description_1,
      en_description_2,
      en_description_3,
      en_description_4,
      en_description_5,
      en_description_6,
      en_description_7,
    };
  },
  // computed: {
  //   features5() {
  //     return {
  //       backgroundImage: `url(${this.image})`,
  //     };
  //   },
  // },
};
</script>

<style lang="scss">
*,
*::before,
*::after {
  box-sizing: border-box;
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.rainbow {
  position: relative;
  z-index: 0;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  padding: 2rem;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #399953;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#399953, #399953),
      linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33),
      linear-gradient(#377af5, #377af5);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: white;
    border-radius: 5px;
  }
}
.text-title {
  font-size: 30px;
  font-weight: 600;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(245, 255, 0, 1) 0%,
    rgba(255, 171, 0, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>

<template>
  <div class="wrapper">
    <div class="section background-blue">
      <!-- Blogs 3 -->

      <div class="container">
        <h1 style="margin-top: 50px; margin-bottom: 50px" class="text-orange">
          <b style="font-size: 40px; font-weight: 800">AUTO REBALANCE BLOG</b>
        </h1>
        <div v-if="items.length > 0">
          <div class="md-layout">
            <div
              v-for="item in items"
              :key="item.title"
              class="md-layout-item md-size-25 md-small-size-100"
              style="margin-top: 15px"
            >
              <div style="border-style: solid; border-color: white">
                <div style="max-height: 120px; overflow: hidden">
                  <img :src="img[item.created]" />
                </div>
                <div style="margin: 5px">
                  <div style="max-height: 120px; overflow: hidden">
                    <h5 style="font-weight: 500">
                      <a style="color: white" :href="item.link" target="_blank"
                        >{{ item.title }}
                      </a>
                    </h5>
                    <p style="font-size: 12px; color: white">
                      {{ content_encoded[item.created] }}
                    </p>
                  </div>
                  <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                      <div style="display: flex; justify-content: flex-end">
                        <a
                          :href="item.link"
                          target="_blank"
                          style="
                            border-style: solid;
                            border-color: white;
                            margin: 10px;
                          "
                        >
                          <a
                            style="padding: 5px; color: white; font-size: 12px"
                          >
                            Read more >>
                          </a>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- End Blogs 3 -->
    </div>
  </div>
</template>

<script>
import { BlogCard } from "@/components";
import axios from "axios";
import { API_URL } from "../../../config";

export default {
  name: "Blogs",
  components: {},
  data() {
    return {
      cardBlogPost: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2022/02/S__5226628.jpg",
      },
      cardBlogPost2: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2021/03/1_A1x1XR7PJEhZiSqSNWxvvw.png",
      },
      cardBlogPost3: {
        cardBlogPost1: require("@/assets/img/1.jpeg"),
      },
      items: [],
      img: {},
      content_encoded: {},
    };
  },
  methods: {},
  created() {
    var b = {};
    var content_encoded = {};
    axios.get(`${API_URL}/mediumFeed`).then((response) => {
      this.items = response.data.rss.items;

      response.data.rss.items.map((i) => {
        var txt = i.content_encoded.substring(
          i.content_encoded.lastIndexOf(`<img alt="" src="`)
        );

        b[i.created] = txt.substring(17, txt.lastIndexOf(" />") - 1);

        var c = i.content_encoded.replace("</p><p>", " ").slice(0, 300);
        c = c.replace("</p>", " ");
        c = c.replace("<p>", " ");
        c = c.replace("<br>", " ");
        c = c.replace("</h3>", " ");
        c = c.replace("<h3>", "");
        c = c.replace("<br>", " ");
        content_encoded[i.created] = c;
      });

      this.img = b;
      this.content_encoded = content_encoded;
    });
  },
};
</script>

<style lang="scss"></style>

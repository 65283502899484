<template>
  <div class="wrapper">
    <div style="color: white" class="bg-color">
      <div class="section section-contact">
        <div class="container">
          <center>
            <div v-if="getLanguage == 'THAI'">
              <h1
                style="font-size: 30px; font-weight: 600; margin: 50px"
                class="text-color"
              >
                <b style="font-size: 60px; font-weight: 800">{{ th_title }}</b>
              </h1>
            </div>
            <div v-else>
              <h1
                style="font-size: 30px; font-weight: 600; margin: 50px"
                class="text-color"
              >
                <b style="font-size: 60px; font-weight: 800">{{ en_title }}</b>
              </h1>
            </div>
          </center>
          <div class="md-layout" id="headers">
            <div class="md-layout-item md-size-90 md-small-size-100 mx-auto">
              <div v-if="getLanguage == 'THAI'">
                <h4 style="font-weight: 500">{{ th_set_time }}</h4>

                <div class="container">
                  <div class="md-layout">
                    <md-button
                      :class="rebalanceSet_1_5_years"
                      @click="setRebalance('1.5years')"
                    >
                      1 ปี 5 เดือน
                    </md-button>
                    <md-button
                      :class="rebalanceSet_1_years"
                      @click="setRebalance('1year')"
                    >
                      1 ปี
                    </md-button>
                    <md-button
                      :class="rebalanceSet_6_month"
                      @click="setRebalance('6month')"
                    >
                      6 เดือน
                    </md-button>
                    <md-button
                      :class="rebalanceSet_3_month"
                      @click="setRebalance('3month')"
                    >
                      3 เดือน
                    </md-button>
                    <md-button
                      :class="rebalanceSet_1_month"
                      @click="setRebalance('1month')"
                    >
                      1 เดือน
                    </md-button>
                  </div>
                </div>
              </div>
              <div v-else>
                <h4 style="font-weight: 500">{{ en_set_time }}</h4>

                <div class="container">
                  <div class="md-layout">
                    <md-button
                      :class="rebalanceSet_1_5_years"
                      @click="setRebalance('1.5years')"
                    >
                      1 years 5 months
                    </md-button>
                    <md-button
                      :class="rebalanceSet_1_years"
                      @click="setRebalance('1year')"
                    >
                      1 years
                    </md-button>
                    <md-button
                      :class="rebalanceSet_6_month"
                      @click="setRebalance('6month')"
                    >
                      6 months
                    </md-button>
                    <md-button
                      :class="rebalanceSet_3_month"
                      @click="setRebalance('3month')"
                    >
                      3 months
                    </md-button>
                    <md-button
                      :class="rebalanceSet_1_month"
                      @click="setRebalance('1month')"
                    >
                      1 months
                    </md-button>
                  </div>
                </div>
              </div>

              <div v-if="getLanguage == 'THAI'">
                <h4 style="font-weight: 500">{{ th_set_summary }}</h4>
                <div class="container">
                  <div class="md-layout">
                    <md-button
                      :class="rebalanceSet_amount_30000"
                      @click="setAmountRebalance('30000')"
                    >
                      30,000 USDT
                    </md-button>

                    <md-button
                      :class="rebalanceSet_amount_10000"
                      @click="setAmountRebalance('10000')"
                    >
                      10,000 USDT
                    </md-button>
                    <md-button
                      :class="rebalanceSet_amount_5000"
                      @click="setAmountRebalance('5000')"
                    >
                      5,000 USDT
                    </md-button>
                    <md-button
                      :class="rebalanceSet_amount_1000"
                      @click="setAmountRebalance('1000')"
                    >
                      1,000 USDT
                    </md-button>
                    <md-button
                      :class="rebalanceSet_amount_specify"
                      @click="setAmountRebalance('')"
                      >ระบุจำนวน
                      <md-input v-model="amountRebalance" type="text" /> USDT
                    </md-button>
                  </div>
                </div>
              </div>
              <div v-else>
                <h4 style="font-weight: 500">{{ en_set_summary }}</h4>
                <div class="container">
                  <div class="md-layout">
                    <md-button
                      :class="rebalanceSet_amount_30000"
                      @click="setAmountRebalance('30000')"
                    >
                      30,000 USDT
                    </md-button>

                    <md-button
                      :class="rebalanceSet_amount_10000"
                      @click="setAmountRebalance('10000')"
                    >
                      10,000 USDT
                    </md-button>
                    <md-button
                      :class="rebalanceSet_amount_5000"
                      @click="setAmountRebalance('5000')"
                    >
                      5,000 USDT
                    </md-button>
                    <md-button
                      :class="rebalanceSet_amount_1000"
                      @click="setAmountRebalance('1000')"
                    >
                      1,000 USDT
                    </md-button>
                    <md-button
                      :class="rebalanceSet_amount_specify"
                      @click="setAmountRebalance('')"
                      >Specify number
                      <md-input v-model="amountRebalance" type="text" /> USDT
                    </md-button>
                  </div>
                </div>
              </div>

              <div v-if="getLanguage == 'THAI'">
                <div class="container" style="color: white">
                  <div class="md-layout">
                    <h4 style="font-weight: 500">{{ th_set_assete }}</h4>
                  </div>

                  <div class="md-layout">
                    <div
                      v-for="index in coinList"
                      :key="index"
                      class="md-layout-item md-size-15 md-small-size-33"
                    >
                      <md-button
                        v-if="coins.includes(index)"
                        class="md-success"
                        @click="selectCoins(index)"
                      >
                        <center>
                          <img
                            :src="require(`@/assets/tokens/${index}.png`)"
                            style="width: 80%"
                            alt=""
                          />
                          <br />
                          <a
                            style="
                              font-size: 12px;
                              font-weight: 500;
                              color: white;
                            "
                          >
                            {{ index }}
                          </a>
                        </center>
                      </md-button>
                      <md-button
                        v-else
                        class="md-translucent"
                        @click="selectCoins(index)"
                      >
                        <center>
                          <img
                            :src="require(`@/assets/tokens/${index}.png`)"
                            style="width: 80%"
                            alt=""
                          />
                          <br />
                          <a
                            style="
                              font-size: 12px;
                              font-weight: 500;
                              color: white;
                            "
                          >
                            {{ index }}
                          </a>
                        </center>
                      </md-button>
                    </div>
                  </div>
                  <br />

                  <md-button class="md-success md-lg" @click="calculators()">
                    <samll style="font-weight: 600; color: white">
                      {{ th_style_button }}
                    </samll>
                  </md-button>
                  <p style="font-size: 14px">
                    ( หลังจาก <b>Reset</b> ข้อมูล กด
                    <b>{{ th_style_button }}</b> เพื่อคำนวน
                    <b>{{ th_title }}</b> )
                  </p>
                </div>
              </div>
              <div v-else>
                <div class="container" style="color: white">
                  <div class="md-layout">
                    <h4 style="font-weight: 500">{{ en_set_assete }}</h4>
                  </div>

                  <div class="md-layout">
                    <div
                      v-for="index in coinList"
                      :key="index"
                      class="md-layout-item md-size-15 md-small-size-33"
                    >
                      <md-button
                        v-if="coins.includes(index)"
                        class="md-success"
                        @click="selectCoins(index)"
                      >
                        <center>
                          <img
                            :src="require(`@/assets/tokens/${index}.png`)"
                            style="width: 80%"
                            alt=""
                          />
                          <br />
                          <a
                            style="
                              font-size: 12px;
                              font-weight: 500;
                              color: white;
                            "
                          >
                            {{ index }}
                          </a>
                        </center>
                      </md-button>
                      <md-button v-else class="" @click="selectCoins(index)">
                        <center>
                          <img
                            :src="require(`@/assets/tokens/${index}.png`)"
                            style="width: 80%"
                            alt=""
                          />
                          <br />
                          <a
                            style="
                              font-size: 12px;
                              font-weight: 500;
                              color: white;
                            "
                          >
                            {{ index }}
                          </a>
                        </center>
                      </md-button>
                    </div>
                  </div>
                  <br />

                  <md-button class="md-success md-lg" @click="calculators()">
                    <samll style="font-weight: 600; color: white">
                      {{ en_style_button }}
                    </samll>
                  </md-button>
                  <p style="font-size: 14px">
                    ( After <b>Reset</b> data click <b>CALCULATOR</b> to
                    <b>Backtest</b> )
                  </p>
                </div>
              </div>

              <!-- <h4 class="">Summary</h4>
              <div class="container">
                <div class="md-layout">
                  <div
                    v-for="index in coins"
                    :key="index"
                    class=""
                    style="margin: 3px"
                  >
                    <img
                      :src="require(`@/assets/tokens/${index}.png`)"
                      style="width: 50%"
                    />
                    <p style="font-size: 14px; font-weight: 500; color: black">
                      {{ index }}
                      <br />
                      <a style="font-size: 12px; color: black"
                        >({{ ratio }}%)</a
                      >
                    </p>
                  </div>
                </div>
                <br />
              </div> -->

              <br />
              <br />

              <canvas id="planet-chart"></canvas>
              <br />

              <div v-if="getLanguage == 'THAI'">
                <p style="font-size: 14px; font-weight: 500">
                  {{ th_warning }}
                </p>
              </div>

              <div v-else>
                <p style="font-size: 14px; font-weight: 500">
                  {{ en_warning }}
                </p>
              </div>

              <br />
              <br />
            </div>
          </div>

          <div v-if="coins_list.length > 0">
            <div class="container">
              <div class="md-layout">
                <div class="md-layout-item md-size-49 md-small-size-100">
                  <h4 style="font-weight: 600">AutoRebalance Backtest :</h4>

                  <div v-if="getLanguage == 'THAI'">
                    <div
                      style="margin: 5px; padding: 5px; border-radius: 10px"
                      class="box-color-green"
                    >
                      <div style="color: black; margin: 15px">
                        <p v-for="coo in coins_list" :key="coo">
                          เริ่ม <b>{{ coo }}</b
                          >:
                          <a style="font-weight: 600">{{
                            summary.rebalance.start[`${coo}`].amount_coin
                          }}</a>
                          <b> {{ coo }}</b>
                        </p>

                        <p>
                          เริ่ม <b>พอตรวม</b>:
                          <a style="font-weight: 600">{{ startSummary }}</a>
                          <b> USDT</b>
                        </p>
                      </div>
                    </div>

                    <div
                      style="margin: 5px; padding: 5px; border-radius: 10px"
                      class="box-color-green"
                    >
                      <div style="color: black; margin: 15px">
                        <p v-for="c in coins_list" :key="c">
                          ปัจุบัน <b>{{ c }}</b> :
                          <a style="font-weight: 600">
                            {{ summary.rebalance.last[`${c}`].amount_coin }}</a
                          >
                          <b> {{ c }}</b>
                        </p>

                        <p>
                          ปัจุบัน <b>พอตรวม</b>:
                          <a style="font-weight: 600">{{
                            summary.rebalance.summary
                          }}</a>
                          <b> USDT</b>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div v-else>
                    <div
                      style="margin: 5px; padding: 5px; border-radius: 10px"
                      class="box-color-green"
                    >
                      <div style="color: black; margin: 15px">
                        <p v-for="coo in coins_list" :key="coo">
                          Start <b>{{ coo }}</b
                          >:
                          <a style="font-weight: 600">{{
                            summary.rebalance.start[`${coo}`].amount_coin
                          }}</a>
                          <b> {{ coo }}</b>
                        </p>

                        <p>
                          Start <b>Summary</b>:
                          <a style="font-weight: 600">{{ startSummary }}</a>
                          <b> USDT</b>
                        </p>
                      </div>
                    </div>

                    <div
                      style="
                        margin: 5px;
                        padding: 5px;

                        border-radius: 10px;
                      "
                      class="box-color-green"
                    >
                      <div style="color: black; margin: 15px">
                        <p v-for="c in coins_list" :key="c">
                          Last <b>{{ c }}</b> :
                          <a style="font-weight: 600">
                            {{ summary.rebalance.last[`${c}`].amount_coin }}</a
                          >
                          <b> {{ c }}</b>
                        </p>

                        <p>
                          Last <b>Summary</b>:
                          <a style="font-weight: 600">{{
                            summary.rebalance.summary
                          }}</a>
                          <b> USDT</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="md-layout-item md-size-49 md-small-size-100">
                  <h4 style="font-weight: 600">Hold Backtest :</h4>

                  <div v-if="getLanguage == 'THAI'">
                    <div
                      style="margin: 5px; padding: 5px; border-radius: 10px"
                      class="box-color-orange"
                    >
                      <div style="color: black; margin: 15px">
                        <p v-for="c in coins_list" :key="c">
                          เริ่ม <b>{{ c }}</b
                          >:
                          <a style="font-weight: 600">{{
                            summary.hold.start[c].amount_coin
                          }}</a>
                          <b> {{ c }}</b>
                        </p>

                        <p>
                          เริ่ม <b>พอตรวม</b>:
                          <a style="font-weight: 600">{{ startSummary }}</a>
                          <b> USDT</b>
                        </p>
                      </div>
                    </div>
                    <div
                      style="margin: 5px; padding: 5px; border-radius: 10px"
                      class="box-color-orange"
                    >
                      <div style="color: black; margin: 15px">
                        <p v-for="c in coins_list" :key="c">
                          ล่าสุด <b>{{ c }}</b
                          >:
                          <a style="font-weight: 600">{{
                            summary.hold.last[c].amount_coin
                          }}</a>
                          <b> {{ c }}</b>
                        </p>
                        <p>
                          ล่าสุด<b> พอตรวม</b>:
                          <a style="font-weight: 600">{{
                            summary.hold.summary
                          }}</a>
                          <b> USDT</b>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div v-else>
                    <div
                      style="margin: 5px; padding: 5px; border-radius: 10px"
                      class="box-color-orange"
                    >
                      <div style="color: black; margin: 15px">
                        <p v-for="c in coins_list" :key="c">
                          Start <b>{{ c }}</b
                          >:
                          <a style="font-weight: 600">{{
                            summary.hold.start[c].amount_coin
                          }}</a>
                          <b> {{ c }}</b>
                        </p>

                        <p>
                          Start <b>Summary</b>:
                          <a style="font-weight: 600">{{ startSummary }}</a>
                          <b> USDT</b>
                        </p>
                      </div>
                    </div>
                    <div
                      style="margin: 5px; padding: 5px; border-radius: 10px"
                      class="box-color-orange"
                    >
                      <div style="color: black; margin: 15px">
                        <p v-for="c in coins_list" :key="c">
                          Last <b>{{ c }}</b
                          >:
                          <a style="font-weight: 600">{{
                            summary.hold.last[c].amount_coin
                          }}</a>
                          <b> {{ c }}</b>
                        </p>
                        <p>
                          Last<b> Summary</b>:
                          <a style="font-weight: 600">{{
                            summary.hold.summary
                          }}</a>
                          <b> USDT</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="md-layout-item md-size-49 md-small-size-100">
                  <h4 style="font-weight: 600">DCA Backtest :</h4>

                  <div v-if="getLanguage == 'THAI'">
                    <div
                      style="margin: 5px; padding: 5px; border-radius: 10px"
                      class="box-color-red"
                    >
                      <div style="color: black; margin: 15px">
                        <p v-for="c in coins_list" :key="c">
                          เริ่ม <b>{{ c }}</b
                          >:

                          <a style="font-weight: 600" v-if="c == `USDT`"> 0 </a>
                          <a v-else>
                            <a v-for="s in summary.dca.start" :key="s">
                              <a v-if="s.name == c" style="font-weight: 600">
                                {{ s.amount_coin }}
                              </a>
                            </a>

                            <a style="font-weight: 600">
                              {{ summary.dca.start.amount }}
                            </a>
                          </a>

                          <b> {{ c }}</b>
                        </p>

                        <p>
                          เริ่ม <b>วันละ</b>:
                          <a style="font-weight: 600">{{ summary.dca.avg }}</a>
                          <b> USDT</b>
                        </p>
                      </div>
                    </div>
                    <div
                      style="margin: 5px; padding: 5px; border-radius: 10px"
                      class="box-color-red"
                    >
                      <div style="color: black; margin: 15px">
                        <p v-for="c in coins_list" :key="c">
                          ล่าสุด <b>{{ c }}</b
                          >:

                          <a style="font-weight: 600" v-if="c == `USDT`"> 0 </a>
                          <a v-else>
                            <a v-for="s in summary.dca.last" :key="s">
                              <a v-if="s.name == c" style="font-weight: 600">
                                {{ s.amount_coin }}
                              </a>
                            </a>

                            <a style="font-weight: 600">
                              {{ summary.dca.last.amount }}
                            </a>
                          </a>

                          <b> {{ c }}</b>
                        </p>
                        <p>
                          ล่าสุด<b> พอตรวม</b>:
                          <a style="font-weight: 600">{{
                            summary.dca.summary
                          }}</a>
                          <b> USDT</b>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div v-else>
                    <div
                      style="margin: 5px; padding: 5px; border-radius: 10px"
                      class="box-color-red"
                    >
                      <div style="color: black; margin: 15px">
                        <p v-for="c in coins_list" :key="c">
                          Start <b>{{ c }}</b
                          >:

                          <a style="font-weight: 600" v-if="c == `USDT`"> 0 </a>
                          <a v-else>
                            <a v-for="s in summary.dca.start" :key="s">
                              <a v-if="s.name == c" style="font-weight: 600">
                                {{ s.amount_coin }}
                              </a>
                            </a>

                            <a style="font-weight: 600">
                              {{ summary.dca.start.amount }}
                            </a>
                          </a>

                          <b> {{ c }}</b>
                        </p>

                        <p>
                          Start <b> per days.</b>:
                          <a style="font-weight: 600">{{ summary.dca.avg }}</a>
                          <b> USDT</b>
                        </p>
                      </div>
                    </div>
                    <div
                      style="margin: 5px; padding: 5px; border-radius: 10px"
                      class="box-color-red"
                    >
                      <div style="color: black; margin: 15px">
                        <p v-for="c in coins_list" :key="c">
                          Last <b>{{ c }}</b
                          >:

                          <a style="font-weight: 600" v-if="c == `USDT`"> 0 </a>
                          <a v-else>
                            <a v-for="s in summary.dca.last" :key="s">
                              <a v-if="s.name == c" style="font-weight: 600">
                                {{ s.amount_coin }}
                              </a>
                            </a>

                            <a style="font-weight: 600">
                              {{ summary.dca.last.amount }}
                            </a>
                          </a>

                          <b> {{ c }}</b>
                        </p>
                        <p>
                          Last<b> Summary</b>:
                          <a style="font-weight: 600">{{
                            summary.dca.summary
                          }}</a>
                          <b> USDT</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />

          <div v-if="getLanguage == 'THAI'">
            <h3 style="font-weight: 600">{{ th_transaction }}</h3>
          </div>
          <div v-else>
            <h3 style="font-weight: 600">{{ en_transaction }}</h3>
          </div>

          <md-table v-model="tableDataStriped" class="table-striped">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="summaryRebalance">
                {{ item.summaryRebalance }}
              </md-table-cell>
              <md-table-cell md-label="summaryHold">
                {{ item.summaryHold }}
              </md-table-cell>

              <md-table-cell md-label="Price">
                {{ item.price }}
              </md-table-cell>
              <md-table-cell md-label="Amount">
                {{ item.coin }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.7.1/Chart.min.js"></script>
<script src="https://unpkg.com/vue-chartjs/dist/vue-chartjs.min.js"></script>

<script>
import Chart from "chart.js";
import axios from "axios";
import Swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";

import Mixins from "@/plugins/basicMixins";
import { API_URL } from "../../config";

import warning_en from "../language/warning_en";
import warning_th from "../language/warning_th";

import {
  th_title,
  th_set_time,
  th_set_summary,
  th_set_assete,
  th_style_button,
  th_warning,
  th_transaction,
} from "../language/backTest_th";

import {
  en_title,
  en_set_time,
  en_set_summary,
  en_set_assete,
  en_style_button,
  en_warning,
  en_transaction,
} from "../language/backTest_en";

export default {
  name: "PlanetChart",
  mixins: [Mixins.ShoppingCart],
  computed: mapGetters(["getLanguage"]),

  data() {
    return {
      th_title,
      th_set_time,
      th_set_summary,
      th_set_assete,
      th_style_button,
      th_warning,
      th_transaction,
      en_title,
      en_set_time,
      en_set_summary,
      en_set_assete,
      en_style_button,
      en_warning,
      en_transaction,
      warning: {},
      rebalanceSet_1_5_years: "md-success",
      rebalanceSet_1_years: "",
      rebalanceSet_6_month: "",
      rebalanceSet_3_month: "",
      rebalanceSet_1_month: "",
      rebalanceSet_amount_30000: "",
      rebalanceSet_amount_10000: "md-success",
      rebalanceSet_amount_5000: "",
      rebalanceSet_amount_1000: "",
      rebalanceSet_amount_specify: "",
      amountRebalance: 0,
      startSummary: 0,
      ratio: "50",
      showTran: false,
      coinList: [
        "BTC",
        "USDT",
        "ETH",
        "BNB",
        "ADA",
        "XRP",
        "SOL",
        "AVAX",
        "DOT",
        "DOGE",
      ],
      coins: ["BTC", "USDT"],
      coins_list: [],
      planetChartData: {
        type: "line",
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  padding: 25,
                },
              },
            ],
          },
        },
      },
      summary: {
        hold: {
          summary: "12381.67",
          last: {
            USDT: { amount_coin: 0, raio: 0, price: 0 },
            BTC: {
              amount_coin: "0.210858",
              raio: "5000",
              price: "47434.80000000",
            },
          },
          start: {
            USDT: { amount_coin: "5000", raio: "5000", price: 1 },
            BTC: {
              amount_coin: "0.105429",
              raio: "5000",
              price: "47425.20000000",
            },
          },
        },
        rebalance: {
          summary: "11928.73",
          last: {
            USDT: { amount_coin: "5964", raio: "5964", price: 1 },
            BTC: {
              amount_coin: "0.101566",
              raio: "5964",
              price: "58740.55000000",
            },
          },
          start: {
            USDT: { amount_coin: "5000", raio: "5000", price: 1 },
            BTC: {
              amount_coin: "0.105429",
              raio: "5000",
              price: "47425.20000000",
            },
          },
        },
      },
      tableDataStriped: [
        {
          id: 1,
          name: "Moleskine Agenda",
          type: "Office",
          qty: "25",
          price: "€ 49",
          amount: "€ 1,225",
        },
      ],
    };
  },
  methods: {
    setAmountRebalance(text) {
      if (text == "30000") {
        this.rebalanceSet_amount_30000 = "md-success";
        this.rebalanceSet_amount_10000 = "";
        this.rebalanceSet_amount_5000 = "";
        this.rebalanceSet_amount_1000 = "";
        this.rebalanceSet_amount_specify = "";
      } else if (text == "10000") {
        this.rebalanceSet_amount_30000 = "";
        this.rebalanceSet_amount_10000 = "md-success";
        this.rebalanceSet_amount_5000 = "";
        this.rebalanceSet_amount_1000 = "";
        this.rebalanceSet_amount_specify = "";
      } else if (text == "5000") {
        this.rebalanceSet_amount_30000 = "";
        this.rebalanceSet_amount_10000 = "";
        this.rebalanceSet_amount_5000 = "md-success";
        this.rebalanceSet_amount_1000 = "";
        this.rebalanceSet_amount_specify = "";
      } else if (text == "1000") {
        this.rebalanceSet_amount_30000 = "";
        this.rebalanceSet_amount_10000 = "";
        this.rebalanceSet_amount_5000 = "";
        this.rebalanceSet_amount_1000 = "md-success";
        this.rebalanceSet_amount_specify = "";
      } else {
        this.rebalanceSet_amount_30000 = "";
        this.rebalanceSet_amount_10000 = "";
        this.rebalanceSet_amount_5000 = "";
        this.rebalanceSet_amount_1000 = "";
        this.rebalanceSet_amount_specify = "md-success";
      }
    },
    setRebalance(text) {
      if (text == "1.5years") {
        this.rebalanceSet_1_5_years = "md-success";
        this.rebalanceSet_1_years = "";
        this.rebalanceSet_6_month = "";
        this.rebalanceSet_3_month = "";
        this.rebalanceSet_1_month = "";
      } else if (text == "1year") {
        this.rebalanceSet_1_5_years = "";
        this.rebalanceSet_1_years = "md-success";
        this.rebalanceSet_6_month = "";
        this.rebalanceSet_3_month = "";
        this.rebalanceSet_1_month = "";
      } else if (text == "6month") {
        this.rebalanceSet_1_5_years = "";
        this.rebalanceSet_1_years = "";
        this.rebalanceSet_6_month = "md-success";
        this.rebalanceSet_3_month = "";
        this.rebalanceSet_1_month = "";
      } else if (text == "3month") {
        this.rebalanceSet_1_5_years = "";
        this.rebalanceSet_1_years = "";
        this.rebalanceSet_6_month = "";
        this.rebalanceSet_3_month = "md-success";
        this.rebalanceSet_1_month = "";
      } else if (text == "1month") {
        this.rebalanceSet_1_5_years = "";
        this.rebalanceSet_1_years = "";
        this.rebalanceSet_6_month = "";
        this.rebalanceSet_3_month = "";
        this.rebalanceSet_1_month = "md-success";
      }
    },
    selectCoins(coin) {
      if (!this.coins.includes(coin)) {
        this.coins.push(coin);
        this.ratio = (100 / this.coins.length).toFixed(0);
      } else {
        this.removeA(this.coins, coin);
        this.ratio = (100 / this.coins.length).toFixed(0);
      }
      // setTimeout(() => {
      //   this.planetChartData.data.labels = [];
      //   this.planetChartData.data.datasets = [];

      //   this.backTest();
      // }, 2500);
    },
    removeA(arr) {
      var what,
        a = arguments,
        L = a.length,
        ax;
      while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1);
        }
      }
      this.coins = arr;
    },
    calculators() {
      Swal.fire({
        title: this.warning.title,
        text: this.warning.description,
        type: "success",
        confirmButtonClass: "btn btn-success",
        confirmButtonText: this.warning.button_text,
        buttonsStyling: true,
      });

      var timing = 0;

      if (this.rebalanceSet_1_5_years == "md-success") {
        timing = 540;
      } else if (this.rebalanceSet_1_years == "md-success") {
        timing = 365;
      } else if (this.rebalanceSet_6_month == "md-success") {
        timing = 183;
      } else if (this.rebalanceSet_3_month == "md-success") {
        timing = 91;
      } else if (this.rebalanceSet_1_month == "md-success") {
        timing = 31;
      }

      var amount = 0;

      if (this.rebalanceSet_amount_30000 == "md-success") {
        amount = 30000;
      } else if (this.rebalanceSet_amount_10000 == "md-success") {
        amount = 10000;
      } else if (this.rebalanceSet_amount_5000 == "md-success") {
        amount = 5000;
      } else if (this.rebalanceSet_amount_1000 == "md-success") {
        amount = 1000;
      } else if (this.rebalanceSet_amount_specify == "md-success") {
        amount = this.amountRebalance;
      }

      setTimeout(() => {
        this.backTest(timing, amount);
      }, 1000);
    },
    backTest(timing = 540, amount = 10000) {
      this.showTran = false;
      this.planetChartData.data.labels = [];
      this.planetChartData.data.datasets = [];
      this.tableDataStriped = [];

      if (this.getLanguage == "THAI") {
        this.warning = warning_th;
      } else {
        this.warning = warning_en;
      }

      // .post(`http://localhost:5000/backtest`, { coins: this.coins, timing })

      // .post(`https://api.autorebalance.co/backtest`, { coins: this.coins, timing })

      axios
        .post(`https://api.autorebalance.co/backtest`, {
          coins: this.coins,
          timing,
          amount,
        })

        .then((response) => {
          this.planetChartData.data.datasets.push({
            label: "Rebalance",
            data: response.data.summaryAll,
            backgroundColor: "rgba(23,242,93,.6)",
            borderColor: "#36495d",
            borderWidth: 1,
          });

          this.planetChartData.data.datasets.push({
            label: "Hold",
            data: response.data.summaryHold,
            backgroundColor: "rgba(242,144,4,.7)",
            borderColor: "#6966",
            borderWidth: 1,
          });

          this.planetChartData.data.datasets.push({
            label: "DCA",
            data: response.data.summaryDCA,
            backgroundColor: "rgba(242,2,4,.7)",
            borderColor: "#6966",
            borderWidth: 1,
          });

          this.startSummary = response.data.startSummary;

          this.planetChartData.data.labels = response.data.priceCoin;
          this.summary = response.data.summary;

          this.coins_list = [];

          var txt = ``;
          // response.data.coinList.map((b) => {
          //   txt =
          //     txt + `${b}: ${response.data.amount_ALL[1].q[b].amount_coin} | `;
          // });

          this.coins.map((c) => {
            this.coins_list.push(c);
          });

          const ctx = document.getElementById("planet-chart");
          new Chart(ctx, this.planetChartData);

          for (var i = 0; i < response.data.summaryAll.length; i++) {
            var txt = ``;
            response.data.coinList.map((b) => {
              response.data.amount_ALL[i].map((cc) => {
                if (cc.name == b) {
                  txt = txt + `${cc.name}: ${cc.amount_coin} | `;
                }
              });
            });

            this.tableDataStriped.push({
              summaryRebalance: response.data.summaryAll[i],
              summaryHold: response.data.summaryHold[i],
              price: response.data.priceCoin[i],
              coin: txt,
            });
          }
        })
        .catch((error) => {
          console.log("error00001", error);
        });
    },
    showTransaction() {
      if (this.showTran) {
        this.showTran = false;
      } else {
        this.showTran = true;
      }
    },
  },
  created() {
    this.backTest();
  },
  mounted() {
    // const ctx = document.getElementById("planet-chart");
    // new Chart(ctx, this.planetChartData);
  },
};
</script>

<style lang="scss">
.table-stats {
  display: flex;
  align-items: center;
  text-align: right;
  flex-flow: row wrap;

  .td-price .td-total {
    display: inline-flex;
    font-weight: 500;
    font-size: 1.0625rem;
    margin-right: 50px;
  }

  .td-price {
    font-size: 26px;
    border-top: 1px solid #ddd;
  }

  .td-price,
  > div {
    flex: 0 0 100%;
    padding: 12px 8px;
  }
}
.bg-color {
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 44, 68, 1) 18%,
    rgba(0, 44, 68, 1) 50%,
    rgba(0, 44, 68, 1) 79%,
    rgba(0, 0, 0, 1) 100%
  );
}
.text-color {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(245, 255, 0, 1) 0%,
    rgba(255, 171, 0, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.box-color-green {
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 255, 0, 1) 0%,
    rgba(0, 144, 69, 1) 100%
  );
}
.box-color-orange {
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(250, 236, 33, 1) 0%,
    rgba(255, 193, 6, 1) 100%
  );
}

.box-color-red {
  background: linear-gradient(
    180deg,
    rgb(214, 122, 110) 0%,
    rgb(240, 62, 31) 0%,
    rgb(231, 100, 100) 100%
  );
}
</style>

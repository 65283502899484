module.exports = {
  th_title: "ทดสอบข้อมูลย้อนหลัง",
  th_set_time: "เลือกระยะเวลาที่จะทดสอบ",
  th_set_summary: "เลือกจำนวนเงินทุนที่ใช้ทดสอบ",
  th_set_assete: "เลือกคู่เหรียญที่ต้องการทดสอบ",
  th_style_button: "คำนวณ",
  th_warning:
    "ทั้งนี้การใช้งาน AutoRebalance , Backtest และ Forwardtest ไม่สามารถการันตีผลลัพธ์หรือผลกำไรในอนาคตได้ การใช้งานดังกล่าวมีความเสี่ยง ไม่แนะนำให้ใช้งานหากไม่ทำการศึกษาข้อมูล ข้อจำกัดและยอมรับเงื่อนไข ผู้ใช้งานควรยอมรับความเสี่ยงก่อนติดสินใจใช้งาน.",
  th_transaction: "ประวัติ",
};

<template>
  <div class="wrapper">
    <!-- Testimonials 1 -->
    <div
      class="section"
      style="background-color: #001a26; color: white; margin-top: -100px"
    >
      <div class="container">
        <div v-if="getLanguage == 'THAI'">
          <h2>
            <b class="text-orange"> {{ th_reviews }} </b>
          </h2>
        </div>
        <div v-else>
          <h2>
            <b class="text-orange"> {{ en_reviews }} </b>
          </h2>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-size-25 md-small-size-100">
            <center>
              <br />

              <a
                target="_blank"
                href="https://www.facebook.com/Cryptofourmink/photos/a.115590341120340/143950254951015/?type=3&mibextid=wxGVb6"
              >
                <img
                  class=""
                  style="border-radius: 50%; width: 70%"
                  src="@/assets/img/reviews/crypto_four.jpeg"
                />
                <h3 style="font-weight: 600" class="text-orange">
                  Crypto fourmink
                </h3>
              </a>
            </center>
          </div>

          <div class="md-layout-item md-size-25 md-small-size-100">
            <center>
              <br />

              <a
                target="_blank"
                href="https://www.facebook.com/photo/?fbid=626772832785522&set=a.230418669087609"
              >
                <img
                  class=""
                  style="border-radius: 50%; width: 70%"
                  src="@/assets/img/reviews/better.jpeg"
                />
                <h3 style="font-weight: 600" class="text-orange">Better Dee</h3>
              </a>
            </center>
          </div>

          <div class="md-layout-item md-size-25 md-small-size-100">
            <br />

            <center>
              <a
                target="_blank"
                href="https://www.facebook.com/Cryptoqueenth/photos/a.114588240897434/189959226693668/?type=3&mibextid=wxGVb6"
              >
                <img
                  class=""
                  style="border-radius: 50%; width: 70%"
                  src="@/assets/img/reviews/crypto_queen.jpeg"
                />
                <h3 style="font-weight: 600" class="text-orange">
                  ริปโตตัวแม่-Cryptoqueen
                </h3>
              </a>
            </center>
          </div>

          <div class="md-layout-item md-size-25 md-small-size-100">
            <center>
              <br />

              <a
                target="_blank"
                href="https://www.facebook.com/110881201065325/posts/pfbid02DUxFFtkihS1n2epQQKGcu6jaVF1rFAPNx6YihdDzicHDsXmeUzVZEERdxRdzzRjpl/?d=n&mibextid=wxGVb6"
              >
                <img
                  class=""
                  style="border-radius: 50%; width: 70%"
                  src="@/assets/img/reviews/cryptoTeller.jpeg"
                />
                <h3 style="font-weight: 600" class="text-orange">
                  Crypto Teller บอกกล่าวเล่าคริปโต
                </h3>
              </a>
            </center>
          </div>

          <div class="md-layout-item md-size-25 md-small-size-100">
            <center>
              <br />

              <a
                target="_blank"
                href="https://cryptonews.in.th/auto-rebalance-strategy-minimize-your-crypto-investment-portfolio-with-rebalancing-bots/?fbclid=IwAR1WJ9CNcS3Jb8oIgPo88PDUfa40wGeZ52jE4OecgFy1Phm-meApLjJauyY"
              >
                <img
                  class=""
                  style="border-radius: 50%; width: 70%"
                  src="@/assets/img/reviews/crypto_new.jpeg"
                />
                <h3 style="font-weight: 600" class="text-orange">
                  Crypto News
                </h3>
              </a>
            </center>
          </div>

          <div class="md-layout-item md-size-25 md-small-size-100">
            <br />
            <br />
            <br />
            <center>
              <a
                target="_blank"
                href="https://region.siamrath.co.th/bkk/Bangkok/31758?fbclid=IwAR1Hl876lpt9gijvKSry82tivdn8NkA9tYg2Cwawn68aZFLIdiqRqrhgJ7c"
              >
                <img
                  class=""
                  style="width: 90%"
                  src="@/assets/img/reviews/siamrath-logo.png"
                />
                <h3 style="font-weight: 600" class="text-orange">สยามรัฐ</h3>
              </a>
            </center>
          </div>

          <div class="md-layout-item md-size-25 md-small-size-100">
            <br />
            <br />
            <br />
            <center>
              <a
                target="_blank"
                href="https://longtuncrypto.com/review-of-10-crypto-trading-bots-the-best-in-2022/?fbclid=IwAR3pDcKQhPm4iGU-RNgKaFNEsEwcfwTbDMKzJ5BNt3eRBdE80eA-004-vAo"
              >
                <img
                  class=""
                  style="width: 90%"
                  src="@/assets/img/reviews/logo1a-v1.jpeg"
                />
                <h3 style="font-weight: 600" class="text-orange">
                  ลงทุนคริปโต
                </h3>
              </a>
            </center>
          </div>
        </div>

        <br />
        <br />

        <div class="md-layout">
          <div
            class="md-layout-item md-size-33 md-small-size-100"
            style="margin-top: 20px"
          >
            <iframe
              width="950"
              height="534"
              src="https://www.youtube.com/embed/mzmI6QofeRE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div
            class="md-layout-item md-size-33 md-small-size-100"
            style="margin-top: 20px"
          >
            <iframe
              width="950"
              height="534"
              src="https://www.youtube.com/embed/nADdItG3ASU"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div
            class="md-layout-item md-size-33 md-small-size-100"
            style="margin-top: 20px"
          >
            <iframe
              width="950"
              height="534"
              src="https://www.youtube.com/embed/c5p68IEvYB4"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <div class="md-layout" style="margin-top: 20px">
          <div
            class="md-layout-item md-size-33 md-small-size-33"
            style="margin-top: 10px"
            v-for="data in imgReviews"
            :key="data"
          >
            <center>
              <img class="rounded" style="max-width: 100%" :src="data.image" />
            </center>
          </div>
        </div>

        <div style="margin: 5px">
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <div style="display: flex; justify-content: flex-end">
                <a
                  target="_blank"
                  href="/reviews"
                  style="
                    border-style: solid;
                    border-color: white;
                    border-radius: 5px;
                    margin-top: 25px;
                  "
                >
                  <a
                    style="
                      padding: 5px;
                      color: white;
                      font-size: 12px;
                      font-weight: 500;
                    "
                  >
                    Read more >>
                  </a>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end Testimonials 1 -->
  </div>
</template>

<script>
import { TestimonialCard } from "@/components";
import { mapGetters } from "vuex";

import { en_strategy, en_reviews } from "../../language/home_en";
import { th_strategy, th_reviews } from "../../language/home_th";

export default {
  name: "Testimonials",
  components: {},
  computed: mapGetters(["getLanguage"]),

  data() {
    return {
      en_strategy,
      en_reviews,
      th_strategy,
      th_reviews,

      testimonials1: require("@/assets/img/dg2.jpg"),
      sectionTestimonials1: {
        testimonials1: require("@/assets/img/faces/card-profile1-square.jpg"),
        testimonials2: require("@/assets/img/faces/card-profile4-square.jpg"),
        testimonials3: require("@/assets/img/faces/card-profile2-square.jpg"),
      },
      sectionTestimonials2: {
        testimonials1: require("@/assets/img/faces/kendall.jpg"),
        testimonials2: require("@/assets/img/faces/christian.jpg"),
      },
      sectionTestimonials3: {
        testimonials1: require("@/assets/img/faces/card-profile6-square.jpg"),
      },

      imgReviews: [
        {
          image: require("@/assets/img/reviews/1.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/13.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/3.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/4.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/5.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/6.jpeg"),
        },
      ],
    };
  },
  methods: {
    sectionTestimonials(image) {
      return {
        backgroundImage: `url(${image})`,
      };
    },
  },
};
</script>

<style lang="scss">
.mt-0 {
  margin-top: 0 !important;
}
</style>

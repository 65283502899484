<template>
  <div class="wrapper">
    <div
      class="section"
      style="background-color: #001a26; color: white; margin-top: -100px"
    >
      <!-- Blogs 3 -->

      <div class="container">
        <h2>
          <b
            style="
              font-size: 30px;
              font-weight: 700;
              background: rgb(2, 0, 36);
              background: linear-gradient(
                90deg,
                rgba(2, 0, 36, 1) 0%,
                rgba(245, 255, 0, 1) 0%,
                rgba(255, 171, 0, 1) 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            "
            >AUTO REBALANCE BLOG</b
          >
        </h2>
        <div v-if="items.length > 0">
          <div class="md-layout">
            <div
              v-for="item in items"
              :key="item.title"
              class="md-layout-item md-size-25 md-small-size-100"
              style="margin-top: 15px"
            >
              <div
                style="
                  border-style: solid;
                  border-color: white;

                  height: 310px;
                "
              >
                <div style="max-height: 120px; overflow: hidden">
                  <img :src="img[item.created]" />
                </div>
                <div style="margin: 10px">
                  <div style="max-height: 120px; overflow: hidden">
                    <h5 style="font-weight: 500">
                      <a style="color: white" :href="item.link" target="_blank"
                        >{{ item.title }}
                      </a>
                    </h5>
                    <p style="font-size: 12px">
                      {{ content_encoded[item.created] }}
                    </p>
                  </div>
                  <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                      <div
                        style="
                          display: flex;
                          justify-content: flex-end;
                          padding-top: 15px;
                        "
                      >
                        <a
                          :href="item.link"
                          target="_blank"
                          style="border-style: solid; border-color: white"
                        >
                          <a
                            style="
                              padding: 10px;
                              color: white;
                              font-size: 12px;
                              margin: 5px;
                            "
                          >
                            Read more >>
                          </a>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- End Blogs 3 -->
    </div>
  </div>
</template>

<script>
import { BlogCard } from "@/components";
import axios from "axios";
import { API_URL } from "../../../config";

export default {
  name: "Blogs",
  components: {},
  data() {
    return {
      cardBlogPost: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2022/02/S__5226628.jpg",
      },
      cardBlogPost2: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2021/03/1_A1x1XR7PJEhZiSqSNWxvvw.png",
      },
      cardBlogPost3: {
        cardBlogPost1: require("@/assets/img/1.jpeg"),
      },
      items: [],
      img: {},
      content_encoded: {},
    };
  },
  methods: {},
  created() {
    var b = {};
    var content_encoded = {};
    axios.get(`${API_URL}/mediumFeed`).then((response) => {
      var items_array = [];

      for (let index = 0; index < 8; index++) {
        items_array.push(response.data.rss.items[index]);
      }

      this.items = items_array;

      items_array.map((i) => {
        var txt = i.content_encoded.substring(
          i.content_encoded.lastIndexOf(`<img alt="" src="`)
        );

        b[i.created] = txt.substring(17, txt.lastIndexOf(" />") - 1);

        var c = i.content_encoded.replace("</p><p>", " ").slice(0, 300);
        c = c.replace("</p>", " ");
        c = c.replace("<p>", " ");
        c = c.replace("<br>", " ");
        c = c.replace("</h3>", " ");
        c = c.replace("<h3>", "");
        c = c.replace("<br>", " ");
        content_encoded[i.created] = c;
      });

      this.img = b;
      this.content_encoded = content_encoded;
    });
  },
};
</script>

<style lang="scss"></style>

<template>
  <div class="wrapper">
    <div class="section background-blue" style="color: white">
      <br />
      <br />

      <center>
        <h1
          style="font-size: 30px; font-weight: 600; margin: 50px"
          class="text-orange"
        >
          <b style="font-size: 3rem; font-weight: 800">Strategies</b>
        </h1>
        <h4>
          <!-- คำอธิบายกลยุทธ์การปรับสมดุลอัตโนมัติทั้งหมดเพื่อให้เข้าใจง่ายมีดังนี้ -->
        </h4>
      </center>

      <div class="container">
        <br />
        <br />

        <div>
          <h3 style="font-weight: 600">
            <b class="text-orange" style="font-weight: 800">Fiat Market</b>
          </h3>
          <h4>
            Operate by stable coin with Crypto currencies as the price swing
            there will be more rebalance occur. <br /><br />
            <b> For example : </b>
            <br />Suppose there is capital of 3,000 usd with no idea, strategies
            or tools to create order as in spot market. In this case 3,000 usd
            will be split into two parts which is 1,500 usd will be coverted to
            BTC and another 1,500 usd will be converted to stablecoin or fiat
            currencies like USD,EUR etc. as the total value went up to 3,030 usd
            the system will balanced the port by spliting eqully to 1,515 usd
            for both side.Incase of total value went down to 2,970 usd the
            system will balanced the port by spliting eqully to 1,485 usd for
            both side. <br /><br />This strategies will support on bearist
            market in which transaction on spot market can be done always and
            you will have more coins in hand. But in bullist maket system will
            sell more crypto to stablecoin or fiat currencies which will create
            cashflow in capital gain. While in kangaroo market is good for this
            strategy as there are more volatile in which there will be more coin
            and fiat currencies or stablecoin.
          </h4>
          <center>
            <img class="" src="@/assets/img/strategies/main_market.jpeg" />
          </center>
        </div>

        <br />

        <div>
          <h3 style="font-weight: 600">
            <b class="text-orange" style="font-weight: 800">Main market</b>
          </h3>
          <h4>
            Crypto pair with Crypto. In the longterm both coins might be
            increase.
            <br />
            <br />
            Having 1BTC expecting it to grow in value is not a choice. Main
            point is to increase number of BTC instead of waiting for the value
            alone by relied on serveral crypto which carried innovation and
            chance of growing similar to BTC.
            <br />
            <br />
            <b>For Example :</b><br />
            Having 1BTC using 50% of total value of BTC and another 50% will be
            the value of ETH. Votalities of coins will be used in the system to
            create transactions.In bearist market is not suitable for this
            strategy as the valueof both will decrease but if focusing on
            numbers of coins ther will be no worries. In bullist market is
            suitable for this strategy as value of both coins will increase. In
            kangaroo market system can change 1BTC to 5BTC in 5 years which
            happened before.

            <br />
            <br />
            Kangaroo market will provide best performance as focusing on coins.
          </h4>
          <center>
            <img class="" src="@/assets/img/strategies/main_2_market.jpeg" />
          </center>
        </div>

        <div>
          <h3 style="font-weight: 600">
            <b class="text-orange" style="font-weight: 800">Multi Rebalance</b>
          </h3>
          <h4>
            Multi Crypto starting from more than 2 Crypto onwards. Rebalancing
            multiple Crypto by not focusing on one particular crypto.

            <br />
            <br />
            <b>For Example :</b><br />
            Suppose there is capital of 10,000 usd with no idea, strategies or
            tools to create order as in spot market. In this case 10,000 usd
            will be split into four parts equally which is 2,500 usd will be
            coverted to BNB ,2,500 usd will be coverted to USDT, 2,500 usd will
            be coverted to ETH and another 2,500 usd will be converted to BTC.
            as the total value went up to 11,000 usd the system will balanced
            the port by spliting eqully to 2,750 usd for all four crypto.Incase
            of total value went down to 9,900 usd the system will balanced the
            port by spliting eqully to 2,475 usd for all four crypto.

            <br />
            <br />
            For multi rebalance strategy can manage more than two currencies
            such as fiat currencies.This strategy forcus on risk management. In
            bearist market totally value of crypto will decrease as there are no
            support from stablecoin which is not suitable for this strategy. In
            bullist market totally value will increase. Using the best coins
            from each categories will increase more capital gain than focusing
            on single crypto.last but not least kangaroo market number of coins
            will increase rather than focusing on the total value.
            <br />
            <br />
            Keep in mind this strategy will consume more time than above
            strategies but result will be better depend on all the coins
            selected to pair.
          </h4>
          <center>
            <img class="" src="@/assets/img/strategies/multi_rebalance.jpeg" />
          </center>
        </div>

        <div>
          <h3 style="font-weight: 600">
            <b class="text-orange" style="font-weight: 800"
              >Ultimate Rebalance</b
            >
          </h3>
          <h4>
            This is a mixed of 1st and 2nd strategies.

            <br />
            <br />
            <b>For Example :</b><br />
            Suppose there is capital of 10,000 usd with no idea, strategies or
            tools to create order as in spot market. In this case 10,000 usd
            will be split into three parts which is 2,500 usd will be coverted
            to ETH,2,500 usd will be coverted to BTC and another 5,500 usd will
            be converted to stablecoin or fiat currencies like USD,EUR etc. as
            the total value went up to 11,000 usd the system will balanced the
            port by spliting into 2,750 usd for both side and 5,500 usd Incase
            of total value went down to 9,900 usd the system will balanced the
            port by spliting into 2,475 usd for both side and 4,950 usd.

            <br />
            <br />
            This strategies will support on bearist market in which transaction
            on spot market can be done always and you will have more coins in
            hand. But in bullist maket system will sell more crypto to
            stablecoin or fiat currencies which will create cashflow in capital
            gain. While in kangaroo market is good for this strategy as there
            are more volatile in which there will be more coin and fiat
            currencies or stablecoin.

            <br />
            <br />
            Better than Fiat Market Strategy ? As this is a mixed strategy from
            fiat market strategy and mainmarket strategy whihc will be more safe
            reflecting on the result to be better than the first strategy as
            using votalities of both crypto.
          </h4>
          <center>
            <img class="" src="@/assets/img/strategies/multi_rebalance.jpeg" />
          </center>
        </div>

        <div>
          <h3 style="font-weight: 600">
            <b class="text-orange" style="font-weight: 800">Fusion Rebalance</b>
          </h3>
          <h4>
            Develop from 1st strategies which is suitable for up trend market no
            suitable for sideways market because this strategy use hodl and
            rebalance at the same time. In case the price rises the bot would
            hold. Sideway and price drop the bot would rebalance.

            <br />
            <br />
            <b>For Example :</b><br />
            Suppose there is capital of 10,000 usd with no idea, strategies or
            tools to create order as in spot market. In this case 10,000 usd
            will be split into two parts which is 5,000 usd will be coverted to
            BTC and another 5,000 usd will be converted to stablecoin or fiat
            currencies like USD,EUR etc. as the total value went up to 11,000
            usd the system will balanced the port by spliting eqully to 5,500
            usd for both side.Incase of total value went down to 9,900 usd the
            system will balanced the port by spliting eqully to 4,450 usd for
            both side. there are different by using hodl strategy not only using
            rebalance.

            <br />
            <br />
            This strategies will support on bearist market in is a normal
            rebalance as mentioned above. But in bullist maket system is most
            suitble to this strategy. hodl will take place in this strategy
            along with rebalance will sell more crypto to stablecoin or fiat
            currencies which will create cashflow in capital gain better than
            fiat market strategy.In case kangaroo market is not suitable for
            this strategy as there are more volatile.This strategy is not likely
            to volatile market.
          </h4>
          <center>
            <img class="" src="@/assets/img/strategies/fusion.jpeg" />
          </center>
        </div>

        <div>
          <h3 style="font-weight: 600">
            <b class="text-orange" style="font-weight: 800">Up coming </b>
          </h3>
          <h4>
            AutoRebalance is doing Reesearch and development according to growth
            of crypto market such as Rebalance on Dex ( decentralized Exchange).

            <br />
            <br />
            To use LP token to rebalance in between will received governance
            token.

            <br />
            <br />

            As LP tokens are only granted to liquidity providers. To receive
            them, you will need to use a DeFi DApp to provide liquidity, such as
            PancakeSwap or Uniswap. The LP token system is common to many
            blockchains, DeFi platforms, automated market makers (AMMs), and
            decentralized exchanges (DEXs).
          </h4>
          <center>
            <img class="" src="@/assets/img/strategies/up_coming.jpeg" />
          </center>
        </div>
      </div>
    </div>
    <nav id="cd-vertical-nav" class="vertical-nav-active">
      <ul>
        <li>
          <a
            href="https://www.facebook.com/autorebalance"
            target="_blank"
            data-number="1"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #0f47f7"
                class="fab fa-facebook"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a
            href="http://line.me/ti/g/fqQ5X1GmYP"
            target="_blank"
            data-number="2"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #5afa33"
                class="fab fa-line"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a
            href="https://www.instagram.com/aiautorebalance/"
            target="_blank"
            data-number="3"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #fa33af"
                class="fab fa-instagram"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a
            href="https://medium.com/@aiautorebalance"
            target="_blank"
            data-number="4"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: white"
                class="fab fa-medium"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { Collapse } from "@/components";

export default {
  name: "Reviews",
  components: {},
  data() {
    return {
      testimonials1: require("@/assets/img/dg2.jpg"),
      cardBlogPost: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2022/02/S__5226628.jpg",
      },
      cardBlogPost2: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2021/03/1_A1x1XR7PJEhZiSqSNWxvvw.png",
      },
      cardBlogPost3: {
        cardBlogPost1:
          "https://scontent.fbkk2-4.fna.fbcdn.net/v/t39.30808-6/274545843_493958235592183_8891905078477806124_n.jpg?_nc_cat=101&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeFtwmjIM0s5aKuP3FP_EKWE9KvFs1u-Bsj0q8WzW74GyHof3DbJchYdAFLJSwOAt-0jBuSrShuHTLMHvmqHAnGz&_nc_ohc=Rc2aeFz0ZeEAX8GMXjR&tn=j0WnZqtCr_hzpKka&_nc_ht=scontent.fbkk2-4.fna&oh=00_AT_hRjG8shrQy4ER-lJWkKeH9omKC4oRT-YFgJqbFjAuog&oe=622664C0",
      },
    };
  },
  methods: {
    sectionTestimonials(image) {
      return {
        backgroundImage: `url(${image})`,
      };
    },
  },
};
</script>

<style lang="scss">
.vertical-nav-active {
  display: block;
}
</style>

module.exports = {
  title_en: "TRADING BOTS",
  title_2_en: "FOR EXPERT TRADERS",
  description_1_en: "",
  description_2_en:
    "As one of the trading bots, Auto Rebalance has a strategy that generates highly efficient cash flow. And with Math Algorithm mathematical computation strategies that are continuously analyzed by the developer team",
  ////

  en_title_why_autorebalance: "Why",
  en_description_why_autorebalance:
    "The only solution that will allow you to live life to the fullest. Ready to earn profits from trading crypto Profit from investment without stress, no sleep deprivation, no need to watch the screen.",
  en_analysis: "Analysis",
  en_analysis_description:
    "Analysis is a strategy suitable for highly volatile markets over a period of 7 years with good results. compared to other markets",
  en_combined_Platform: "Combined Platform",
  en_combined_Platform_description:
    "Platform integrated with leading global exchanges, fast, highly liquid and easy to use.",
  en_trading_AR: "Trading AR",
  en_trading_AR_description:
    "An algorithm that rebalances short term and long term portfolios. for professional investors",
  en_efficiency: "Efficiency",
  en_efficiency_description:
    "A risk management strategy that enhances portfolio performance in the cryptocurrency market.",
  en_safety: "Safety",
  en_safety_description:
    "Highly secure technology based port management process",
  en_service: "Service",
  en_service_description:
    "Quality of data service in depth knowledge and support 24 hours a day",

  /////

  en_management_strategy:
    "Auto Rebalance, an expert  trader's risk management strategy.",
  en_description_1:
    "It is an easy tool to use for entering the cryptocurrency market and manage risks to a minimum",
  en_description_2:
    "It is a good strategy in highly volatile markets like cryptocurrencies.",
  en_description_3:
    "Know the pros and cons In order to choose the appropriate use with capital, timing, volatility, risk, understanding of the circle of the cryptocurrency market.",
  en_description_4:
    "Trading bots with pre-training both online and offline. They can also study repeatedly and have a team update news. throughout the contract",
  en_description_5:
    "Knowledge of API usage and personal security to reduce investment risks",
  en_description_6:
    "There is a team of programmers, support department, available 24 hours a day, with standby care throughout the service contract.",
  en_description_7:
    "Set up usage users can set up applications and design a trading portfolio. You can go through the team by yourself, like writing a trading bot yourself.",
  en_reviews: "REVIEW",
  en_strategy: "STRATEGY",
};

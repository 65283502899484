<template>
  <div class="wrapper">
    <div
      class="section section-testimonials-1"
      style="background-image: linear-gradient(#000000, #001a26)"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-100 mx-auto text-center"
          >
            <h1
              style="font-size: 40px; font-weight: 600; margin: 50px"
              class="text-orange"
            >
              <b style="font-size: 60px; font-weight: 800">Podcast</b>
            </h1>
          </div>
        </div>
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-100"
            style="margin-top: 20px"
          >
            <div
              class=""
              style="
                background-color: white;
                padding: 15px;
                border-radius: 10px;
              "
            >
              <template>
                <iframe
                  style="border-radius: 10px"
                  width="966"
                  height="543"
                  src="https://www.youtube.com/embed/RFumN8nWsSc"
                  title="Cryptociety Podcast l Seek Sonic EP : 07 สิบบทเรียนจากตลาดคริปโต"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>

                <h4 style="font-weight: 500">
                  EP : 07 สิบบทเรียนจากตลาดคริปโต
                </h4>

                <a style="color: black">
                  Cryptociety Podcast l Seek Sonic EP : 07
                  สิบบทเรียนจากตลาดคริปโต
                </a>
                <br />
                <a href="#" @click="showPodcast('7')"> อ่านเพิ่มเติม... </a>

                <div v-if="showEP.ep7 == true">
                  <h4>
                    Blockchain
                    คือเทคโนโลยีที่สามารถนำไปใช้ได้หลากหลายในอุตสากรรมทางการเงินการธนาคาร
                    ทั้งในเรื่องการโอนเงิน, การชำระเงิน ฯลฯ
                  </h4>

                  <ul>
                    <li>
                      การโอนเงิน และการชำระเงิน
                      เป็นกรณีการใช้งานที่แพร่หลายที่สุด
                      รวมถึงการสร้างเงินสกุลดิจิทัลขึ้นมาใหม่
                      หรือการเปลี่ยนสกุลเงินทั่วไปให้กลายเป็นเงินดิจิทัล
                      ซึ่งจะอยู่ในระบบปิด แตกต่างจากระบบเปิดของสกุลเงิน Bitcoin
                      และ Ethereum นอกจากนี่ Blockchain ยังลดเวลาในการทำธุรกรรม,
                      เพิ่มความปลอดภัยและเพิ่มความสามารถในการบันทึกข้อมูลเพื่อการตรวจสอบในภายหลัง
                      ซึ่งจะช่วยลดเวลาและต้นทุนในการจัดการ
                    </li>
                    <li>
                      การซื้อขายพันธบัตรและหุ้น
                      แต่เดิมนั้นมีต้องใช้บุคคลากรหลายฝ่ายในการดำเนินการ
                      รวมไปถึงขั้นตอนที่ยุ่งยาก, ซับซ้อนและใช้เวลานาน Blockchain
                      สามารถช่วยแก้ไขในส่วนนี้ได้
                      ทั้งลดจำนวนผู้เกี่ยวข้อง,ลดต้นทุนและระยะเวลาในการดำเนินการ
                    </li>
                    <li>
                      การชำระเงินแบบ Peep to Peer และการโอนเงินระหว่างประเทศ
                      ปัจจุบันระบบ Mobile Wallet นั้นมีการใช้งานอย่างแพร่หลาย
                      ทำให้เกิดปัญหาในการส่งข้อมูลระหว่างผู้ใช้งาน Application
                      และธนาคารผู้ใช้บริการ
                      เนื่องจากระบบของธนาคารเป็นระบบปิดที่ไม่สามารถเชื่อมต่อกันได้
                      ซึ่ง Blockchain สามารถแก้ปัญหา
                      โดยทำงานอยู่เบื้องหลังการทำธุรกรรมข้าม Application ทำให้
                      Mobile Wallet สามารถเชื่อมต่อข้อมูลได้อย่างปลอดภัย
                      และมีการจัดเก็บข้อมูลที่สามารถตรวจสอบย้อนหลังได้
                      ทำให้สามารถโอนเงินได้อย่างปลอดภัย และมีค่าธรรมเนียมที่ต่ำ
                      และสามารถดำเนินการผ่านระบบ Movile Application
                      โดยไม่ผ่านระบบธนาคาร
                    </li>
                    <li>
                      การรักษาความปลอดภัยและแบ่งปันข้อมูลการยืนยันตัวตนของลูกค้า
                      (Know your customer: KYC)
                      อย่างที่ทุกท่านทราบว่าข้อมูลที่ถูกบันทึกลงใน Blockchain
                      ไม่สามารถแก้ไขได้
                      จึงใช้คุณสมบัติส่วนนี้ในการบันทึกข้อมูลสำคัญๆเช่น
                      การออกหนังสือค้ำประกัน หรือ Letter of Garantee (LG)
                      และการบันทึกข้อมูลการยืนยันตัวตนของลูกค้า (Know your
                      customer: KYC)
                      โดยเข้ามาช่วยเรื่องการอนุญาตการเข้าถึงข้อมูล (Access
                      Authentication)
                    </li>
                    <li>
                      การเพิ่มประสิทธิภาพของงานเทรดไฟแนนซ์ และงานประกัน
                      โดยทั่วไปในการส่งสินค้า และชำระเงินระว่างประเทศ
                      ซึ่งต้องใช้เอกสาร Letter of Credit (L/C) และ Bill of
                      Landing (B/L)
                      ซึ่งต้องผ่านกระบวนการที่ยุ่งยากและมีบุคคลที่สามเข้ามาเกี่ยวข้องมากมาย
                      Blockchain สามารถลดบุคคลที่เกี่ยวข้อง,
                      ขั้นตอนในการดำเนินการและความยุ่งยากต่างๆ
                      สามารถส่งมอบเอกสารได้อย่างปลอดภัย หน้าที่หลักสำคัญใน Smart
                      Contract ของ Blockchain
                      ยังสามารถเร่งความเร็วในกระบวกจัดการต่างๆ
                      ทำหน้าที่แทนมนุษย์ในการตรวจสอบความถูกต้องสมบูรณ์
                      และมั่นใจได้ว่าข้อมูลในเอกสารจะไม่ถูกเปลี่ยนแปลงโดยไม่ได้รับอนุญาตและยังบันทึกข้อมูลจากฐานข้อมูลต่างๆลงในบัญชีเดียวกันได้อย่างสมบูรณ์
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </div>

          <div
            class="md-layout-item md-size-50 md-small-size-100"
            style="margin-top: 20px"
          >
            <div
              style="
                background-color: white;
                padding: 15px;
                border-radius: 10px;
              "
            >
              <template>
                <iframe
                  style="border-radius: 10px"
                  width="966"
                  height="543"
                  src="https://www.youtube.com/embed/e9--Rgtqb4k"
                  title="Cryptociety Podcast l Seek Sonic EP : 06 กระเป๋าคริปโต คืออะไร?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>

                <h4 style="font-weight: 500">EP : 06 กระเป๋าคริปโต คืออะไร?</h4>

                <a style="color: black">
                  Cryptociety Podcast l Seek Sonic EP : 06 กระเป๋าคริปโต
                  คืออะไร?
                </a>
                <br />
                <a href="#"> อ่านเพิ่มเติม... </a>

                <div v-if="showEP.ep6 == true"></div>
              </template>
            </div>
          </div>

          <div
            class="md-layout-item md-size-50 md-small-size-100"
            style="margin-top: 20px"
          >
            <div
              style="
                background-color: white;
                padding: 15px;
                border-radius: 10px;
              "
            >
              <template>
                <iframe
                  style="border-radius: 10px"
                  width="966"
                  height="543"
                  src="https://www.youtube.com/embed/U4kQ4iLUmtU"
                  title="Cryptociety Podcast l Seek Sonic EP : 05 อาชีพที่น่าสนใจ ในสายงานคริปโต"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>

                <h4 style="font-weight: 500">EP : 05 อาชีพที่น่าสนใจ</h4>

                <a style="color: black">
                  Cryptociety Podcast l Seek Sonic EP : 05 อาชีพที่น่าสนใจ
                  ในสายงานคริปโต
                </a>
                <br />
                <a href="#"> อ่านเพิ่มเติม... </a>

                <div v-if="showEP.ep5 == true"></div>
              </template>
            </div>
          </div>
          <div
            class="md-layout-item md-size-50 md-small-size-100"
            style="margin-top: 20px"
          >
            <div
              style="
                background-color: white;
                padding: 15px;
                border-radius: 10px;
              "
            >
              <template>
                <iframe
                  style="border-radius: 10px"
                  width="966"
                  height="543"
                  src="https://www.youtube.com/embed/BaUj-p72UCk"
                  title="Cryptociety Podcast l Seek Sonic EP : 04 Blockchain มีประโยชน์อย่างไร?"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <h4 style="font-weight: 500">
                  EP : 04 Blockchain มีประโยชน์อย่างไร?
                </h4>

                <a style="color: black">
                  Cryptociety Podcast Seek Sonic EP : 04 Blockchain
                  มีประโยชน์อย่างไร?
                </a>
                <br />
                <a href="#" @click="showPodcast('4')"> อ่านเพิ่มเติม... </a>

                <div v-if="showEP.ep4 == true">
                  <h4>
                    Blockchain
                    คือเทคโนโลยีที่สามารถนำไปใช้ได้หลากหลายในอุตสากรรมทางการเงินการธนาคาร
                    ทั้งในเรื่องการโอนเงิน, การชำระเงิน ฯลฯ
                  </h4>

                  <ul>
                    <li>
                      การโอนเงิน และการชำระเงิน
                      เป็นกรณีการใช้งานที่แพร่หลายที่สุด
                      รวมถึงการสร้างเงินสกุลดิจิทัลขึ้นมาใหม่
                      หรือการเปลี่ยนสกุลเงินทั่วไปให้กลายเป็นเงินดิจิทัล
                      ซึ่งจะอยู่ในระบบปิด แตกต่างจากระบบเปิดของสกุลเงิน Bitcoin
                      และ Ethereum นอกจากนี่ Blockchain ยังลดเวลาในการทำธุรกรรม,
                      เพิ่มความปลอดภัยและเพิ่มความสามารถในการบันทึกข้อมูลเพื่อการตรวจสอบในภายหลัง
                      ซึ่งจะช่วยลดเวลาและต้นทุนในการจัดการ
                    </li>
                    <li>
                      การซื้อขายพันธบัตรและหุ้น
                      แต่เดิมนั้นมีต้องใช้บุคคลากรหลายฝ่ายในการดำเนินการ
                      รวมไปถึงขั้นตอนที่ยุ่งยาก, ซับซ้อนและใช้เวลานาน Blockchain
                      สามารถช่วยแก้ไขในส่วนนี้ได้
                      ทั้งลดจำนวนผู้เกี่ยวข้อง,ลดต้นทุนและระยะเวลาในการดำเนินการ
                    </li>
                    <li>
                      การชำระเงินแบบ Peep to Peer และการโอนเงินระหว่างประเทศ
                      ปัจจุบันระบบ Mobile Wallet นั้นมีการใช้งานอย่างแพร่หลาย
                      ทำให้เกิดปัญหาในการส่งข้อมูลระหว่างผู้ใช้งาน Application
                      และธนาคารผู้ใช้บริการ
                      เนื่องจากระบบของธนาคารเป็นระบบปิดที่ไม่สามารถเชื่อมต่อกันได้
                      ซึ่ง Blockchain สามารถแก้ปัญหา
                      โดยทำงานอยู่เบื้องหลังการทำธุรกรรมข้าม Application ทำให้
                      Mobile Wallet สามารถเชื่อมต่อข้อมูลได้อย่างปลอดภัย
                      และมีการจัดเก็บข้อมูลที่สามารถตรวจสอบย้อนหลังได้
                      ทำให้สามารถโอนเงินได้อย่างปลอดภัย และมีค่าธรรมเนียมที่ต่ำ
                      และสามารถดำเนินการผ่านระบบ Movile Application
                      โดยไม่ผ่านระบบธนาคาร
                    </li>
                    <li>
                      การรักษาความปลอดภัยและแบ่งปันข้อมูลการยืนยันตัวตนของลูกค้า
                      (Know your customer: KYC)
                      อย่างที่ทุกท่านทราบว่าข้อมูลที่ถูกบันทึกลงใน Blockchain
                      ไม่สามารถแก้ไขได้
                      จึงใช้คุณสมบัติส่วนนี้ในการบันทึกข้อมูลสำคัญๆเช่น
                      การออกหนังสือค้ำประกัน หรือ Letter of Garantee (LG)
                      และการบันทึกข้อมูลการยืนยันตัวตนของลูกค้า (Know your
                      customer: KYC)
                      โดยเข้ามาช่วยเรื่องการอนุญาตการเข้าถึงข้อมูล (Access
                      Authentication)
                    </li>
                    <li>
                      การเพิ่มประสิทธิภาพของงานเทรดไฟแนนซ์ และงานประกัน
                      โดยทั่วไปในการส่งสินค้า และชำระเงินระว่างประเทศ
                      ซึ่งต้องใช้เอกสาร Letter of Credit (L/C) และ Bill of
                      Landing (B/L)
                      ซึ่งต้องผ่านกระบวนการที่ยุ่งยากและมีบุคคลที่สามเข้ามาเกี่ยวข้องมากมาย
                      Blockchain สามารถลดบุคคลที่เกี่ยวข้อง,
                      ขั้นตอนในการดำเนินการและความยุ่งยากต่างๆ
                      สามารถส่งมอบเอกสารได้อย่างปลอดภัย หน้าที่หลักสำคัญใน Smart
                      Contract ของ Blockchain
                      ยังสามารถเร่งความเร็วในกระบวกจัดการต่างๆ
                      ทำหน้าที่แทนมนุษย์ในการตรวจสอบความถูกต้องสมบูรณ์
                      และมั่นใจได้ว่าข้อมูลในเอกสารจะไม่ถูกเปลี่ยนแปลงโดยไม่ได้รับอนุญาตและยังบันทึกข้อมูลจากฐานข้อมูลต่างๆลงในบัญชีเดียวกันได้อย่างสมบูรณ์
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </div>
          <div
            class="md-layout-item md-size-50 md-small-size-100"
            style="margin-top: 20px"
          >
            <div
              style="
                background-color: white;
                padding: 15px;
                border-radius: 10px;
              "
            >
              <template>
                <iframe
                  style="border-radius: 10px"
                  width="966"
                  height="543"
                  src="https://www.youtube.com/embed/4aMurD12BO4"
                  title="มนุษย์กับความเสี่ยง | Seek Sonic EP : 03"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>

                <h4 style="font-weight: 500">EP : 03 มนุษย์กับความเสี่ยง</h4>

                <a style="color: black">
                  Cryptociety Podcast l Seek Sonic EP : 03 มนุษย์กับความเสี่ยง
                </a>
                <br />
                <a href="#" @click="showPodcast('3')"> อ่านเพิ่มเติม... </a>

                <div v-if="showEP.ep3 == true">
                  <h4>มนุษย์กับความเสี่ยง</h4>

                  <ul>
                    <li>
                      ความเสี่ยงนั้นอยู่คู่กับมนุษย์มาตั้งแต่สมัยดึกดำบรรพ์
                      ในอดีตมนุษย์อาจยอมเสี่ยงในการเดินทางดั้นด้นหาสินค้าจากแดนไกลที่แสนอันตราย
                      เพื่อให้ได้มาซึ่งสินค้าที่เป็นที่ต้องการและมีราคาสูง
                      ฝ่าฝันอุปสรรคนานัปการเพื่อหวังจะได้รับผลตอบแทนหรือผลกำไรที่คุ้มค่ากับความเสี่ยงนั้นๆ
                    </li>
                    <li>
                      หากพูดถึงความเสี่ยงในปัจจุบันก็มีหลากหลายรูปแบบ
                      ซึ่งการลงทุนที่กำลังเป็นที่นิยมและได้รับการพูดถึงคงจะไม่พ้นการลงทุนในสินทรัพย์ดิจิทัล
                      บางคนอาจจะกำลังเรียนรู้และทำความเข้าใจ
                      โดยศึกษาจากสื่อต่างๆ ทั้งจากคนดังหลายๆคน
                      ที่มักสร้างกระแสว่าสามารถสร้างรายได้เป็นกอบเป็นกำตั้งแต่อายุยังน้อย
                      แต่เหรียญมักมี 2 ด้านเสมอ
                      อย่ามองเพียงด้านที่สวยงามเท่านั้น
                    </li>
                    <li>
                      โลกคริปโตหากผู้ที่เข้าใจจะทราบถึงความผันผวนที่รุนแรง
                      สามารถสร้างกำไรจนเป็นมหาเศรษฐีได้ในชั่วข้ามคืน
                      แต่ในโลกใบเดียวกันนี้ก็อาจจะทำให้คุณล้มละลายได้ในชั่วพริบตาเช่นกัน
                    </li>
                    <li>
                      การโดน Hack ซึ่งภายใน Blockchain ที่หลายๆคนคิดว่าปลอดภัย
                      ก็อาจจะโดนขโมยเหรียญกระเป๋าของคุณได้
                      โดยที่แทบไม่มีโอกาสตามทวงคืนได้เลย เหล่า Scammer
                      ที่เข้ามาล่อลวงในรูปแบบใหม่ๆไม่ซ้ำกันทุกสัปดาห์
                      เพื่อให้คุณกด Link และรับ Malware
                      จากนั้นก็ดูดข้อมูลส่วนตัวของคุณ
                      และขโมยเหรียญไปอย่างง่ายดาย
                    </li>
                    <li>
                      การ RugPull เป็นการสร้าง Platform หรือ เหรียญขึ้นมา
                      จากนั้นก็ทำการตลาดให้ยิ่งใหญ่เพื่อให้นักลงทุนแห่นำเงินเข้าไปเพราะคาดหวังผลกำไรที่สูงลิ่ว
                      จากนั้นก็ทิ้งโครงการและถอนเงินออกไปเฉยๆ ง่ายๆ แบบนั้นเลย
                      โดยที่เราทำได้เพียงยืนดูและ “รู้งี้”
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </div>

          <div
            class="md-layout-item md-size-50 md-small-size-100"
            style="margin-top: 20px"
          >
            <div
              style="
                background-color: white;
                padding: 15px;
                border-radius: 10px;
              "
            >
              <template>
                <iframe
                  style="border-radius: 10px"
                  width="966"
                  src="https://www.youtube.com/embed/HFWMLO1aDqo"
                  title="4 วิธีล่าเหรียญ New High l Seek Sonic EP : 02"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>

                <h4 style="font-weight: 500">
                  EP : 2 4 วิธีล่าเหรียญ New High
                </h4>

                <a style="color: black">
                  4 วิธีล่าเหรียญ New High l Seek Sonic EP : 02
                </a>
                <br />
                <a href="#" @click="showPodcast('2')"> อ่านเพิ่มเติม... </a>

                <div v-if="showEP.ep2 == true">
                  <h4>newnew4 วิธีล่าเหรียญ New high</h4>

                  <ul>
                    <li>
                      Roadmap นับเป็นปัจจัยที่สำคัญมาก ๆ ในโลกคริปโต
                      ไม่ว่าจะเป็นโปรเจกต์คริปโตตัวไหนก็ตาม เนื่องจาก Roadmap
                      จะเป็นตัวสะท้อนภาพรวมของโปรเจกต์คริปโตตัวนั้น ๆ
                      รวมถึงวิธีในการดำเนินการตั้งแต่อดีตจนถึงปัจจุบัน
                      เพื่อที่นักลงทุนจะใช้ข้อมูลในส่วนตรงนี้ประกอบการตัดสินใจในการลงทุน
                    </li>

                    <li>
                      เราจะพบว่า เหรียญคริปโตที่สามารถทำ new high
                      ได้ในช่วงที่ผ่านมาได้ มักจะอยู่ใน Category
                      หรือประเภทเดียวกัน พากันราคาขึ้นยกแผง เช่น กลุ่ม Mining,
                      กลุ่มเชน และกลุ่ม Business
                      ซึ่งเป็นกลุ่มใหญ่ในอุตกรรมคริปโต
                      แล้วกลุ่มเหรียญคริปโตที่เป็นกระแสอยู่ตอนนี้
                      ก็คงหนีไม่พ้นกลุ่ม Meme, Defi , และ NFT
                      แต่เหรียญในกลุ่มนี้จะมีความผันผวนที่สูงมาก
                      เนื่องจากกระแสยอมรับคริปโตเริ่มมีความหลากหลายมากขึ้น อายุ
                      อาชีพ ความสนใจ แต่อย่างไรก็ตาม เหรียญทุกกลุ่ม ทุกประเภท
                      เราจะเห็นว่าขึ้นกันแบบยกแพงเลยในปี 2019–2020
                      ดังนั้นก็หากเราสามารถคัดกรองเหรียญในกลุ่มใหญ่ ๆ
                      ที่คาดว่าจะเป็นเทรนด์ได้ถูกต้อง
                      พอร์ตของเราก็สามารถเกาะเทรนด์ขาขึ้นในรอบหน้าและทำนิวไฮครั้งต่อไปได้ไม่ยาก
                    </li>
                    <li>
                      ท่ามกลางตลาดหมี
                      หรืออุตสาหกรรมคริปโตที่กำลังย่ำแย่แบบสุดขีดเช่นนี้
                      มีเหรียญไหนบ้างที่สามารถเติบโตได้อย่างโดดเด่น
                      และเหรียญคริปโตใดบ้างที่มีมูลค่าลดลงไม่มากนัก จากระดับ ATH
                      ยกตัวอย่างเหรียญ Standing
                      ในปัจจุบันที่สังเกตเห็นได้อย่างชัดเจน ก็อย่างเช่น BNB,
                      Matic , TRX และ Dogecoin เป็นต้น
                      จึงสามารถกล่าวได้ว่าเหรียญที่สามารถเติบโตในสวนทางตลาดหมี
                      มีโอกาสสูงที่จะทำนิวไฮอีกครั้งในช่วงขาขึ้นรอบถัดไป
                    </li>
                    <li>
                      กราฟราคาเป็นอีกหนึ่งปัจจัยหนึ่งที่สามารถบ่งบอกได้ว่าในระยะยาว
                      เหรียญคริปโตตัวนั้นมีแนวโน้มที่ดีหรือไม่
                      มีมุมองศาที่แข็งแกร่งหรือไม่ เมื่อเทียบสภาวะตลาดในปัจจุบัน
                      และเป็นขาขึ้น มากกว่าเทรนด์ขาลง
                      นั้นจึงอาจหมายความได้ว่าในเชิงเทคนิคแล้ว
                      เหรียญเหล่านั้นอาจจะยังไม่หลุดเทรนด์ขาขึ้น
                      และมีแนวโน้มที่จะกลับมาเฉิดฉายอีกครั้งในช่วง Bullrun
                      รอบต่อไป
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </div>

          <div
            class="md-layout-item md-size-50 md-small-size-100"
            style="margin-top: 20px"
          >
            <div
              style="
                background-color: white;
                padding: 15px;
                border-radius: 10px;
              "
            >
              <template>
                <iframe
                  style="border-radius: 10px"
                  width="966"
                  height="543"
                  src="https://www.youtube.com/embed/_YxWgTho9zI"
                  title="Cryptociety Podcast l SEEK SONIC EP : 01 'เราลงทุนเพื่ออะไร?''"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>

                <h4 style="font-weight: 500">EP : 01 "เราลงทุนเพื่ออะไร?"</h4>

                <a style="color: black">
                  Cryptociety Podcast l SEEK SONIC EP : 01 "เราลงทุนเพื่ออะไร?"
                </a>
                <br />
                <a href="#" @click="showPodcast('1')"> อ่านเพิ่มเติม... </a>

                <div v-if="showEP.ep1 == true">
                  <h4>เราลงทุนเพื่ออะไร?</h4>

                  <ul>
                    <li>
                      การลงทุนตามคำนิยามคือ
                      การลงทุนคือการนำเงินที่เก็บสะสมมาไปสร้างผลตอบแทนที่สูงขึ้น
                      เช่นการลงทุนในพันธบัตรรัฐบาล
                      หรือการลงทุนในหุ้นหรือภาคอุตสาหกรรมต่างๆ แบ่งออกเป็น 2
                      ลักษณะคือ การลงทุนในสินทรัพย์ที่จับต้องได้เป็นรูปธรรม
                      เช่นลงทุนในทองคำ หรืออสังหาริมทรัพย์
                      ที่สามารถเปลี่ยนเป็นเงินสดได้ทันทีการลงทุนในสินทรัพย์ไม่เห็นประโยชน์ในรูปแบบการใช้งานอย่างชัดเจน
                      เช่นการลงทุนทางการเงินได้แก่ หุ้น,
                      พันธบัตรและสินทรัพย์ดิจิทัล
                    </li>
                    <li>ปัจจัยสำคัญในการลงทุน</li>
                    <li>
                      เงินทุน ถือเป็นส่วนสำคัญในการลงทุน
                      ไม่ว่าเงินทุนนั้นๆจะได้มาจากการเก็บออมจาก รายได้ประจำ,
                      เงินทุนสำรองเลี้ยงชีพจากหน่วยงานหรือบริษัทหรือ
                      เงินเก็บจากรายได้พิเศษ (โบนัส)
                    </li>
                    <li>
                      ผลตอบแทนจากการลงทุน เช่นดอกเบี้ย, กำไรจากการซื้อ-ขาย,
                      ค่าเช่าจากอสังหาริมทรัพย์,
                      สิทธิพิเศษต่างๆที่ได้รับจาการถือหุ้น หรือพันธบัตรต่างๆ
                    </li>
                    <li>
                      การประเมินปัจจัยเสี่ยง
                      หากตัดเงื่อนไขด้านรูปแบบการลงทุนและความเสี่ยง
                      นักลงทุนทุกท่านคงจะเลือกรูปแบบการลงทุนที่ให้ผลตอบแทนสูงที่สุด
                      หากได้รับข่าวสารมาว่ามีการลงทุนประเภทหนึ่งที่ให้ผลตอบแทนที่สูงมากๆ
                      สิ่งแรกที่นักลงทุนความประเมินคือ ที่มาของแหล่งข้อมูล,
                      ความน่าเชื่อถือของข้อมูล และความเสี่ยง ผึงระลึกไว้เสมอว่า
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end Testimonials 1 -->

    <nav id="cd-vertical-nav" class="vertical-nav-active">
      <ul>
        <li>
          <a
            href="https://www.facebook.com/autorebalance"
            target="_blank"
            data-number="1"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #0f47f7"
                class="fab fa-facebook"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a href="http://line.me/ti/g/fqQ5X1GmYP" target="_blank">
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #5afa33"
                class="fab fa-line"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a href="https://www.instagram.com/aiautorebalance/" target="_blank">
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #fa33af"
                class="fab fa-instagram"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a href="https://medium.com/@aiautorebalance" target="_blank">
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: black"
                class="fab fa-medium"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Reviews",
  components: {},
  computed: mapGetters(["getLanguage"]),

  data() {
    return {
      testimonials1: require("@/assets/img/dg2.jpg"),
      cardBlogPost: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2022/02/S__5226628.jpg",
      },
      cardBlogPost2: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2021/03/1_A1x1XR7PJEhZiSqSNWxvvw.png",
      },
      cardBlogPost3: {
        cardBlogPost1:
          "https://scontent.fbkk2-4.fna.fbcdn.net/v/t39.30808-6/274545843_493958235592183_8891905078477806124_n.jpg?_nc_cat=101&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeFtwmjIM0s5aKuP3FP_EKWE9KvFs1u-Bsj0q8WzW74GyHof3DbJchYdAFLJSwOAt-0jBuSrShuHTLMHvmqHAnGz&_nc_ohc=Rc2aeFz0ZeEAX8GMXjR&tn=j0WnZqtCr_hzpKka&_nc_ht=scontent.fbkk2-4.fna&oh=00_AT_hRjG8shrQy4ER-lJWkKeH9omKC4oRT-YFgJqbFjAuog&oe=622664C0",
      },
      imgReviews: [
        {
          image: require("@/assets/img/reviews/1.jpeg"),
        },
      ],
      showEP: {
        ep1: false,
        ep2: false,
        ep3: false,
        ep4: false,
        ep5: false,
        ep6: false,
        ep7: false,
      },
    };
  },
  methods: {
    sectionTestimonials(image) {
      return {
        backgroundImage: `url(${image})`,
      };
    },
    showPodcast(ep) {
      if (ep == "1") {
        if (this.showEP.ep1 == true) {
          this.showEP.ep1 = false;
        } else {
          this.showEP.ep1 = true;
        }

        this.showEP.ep2 = false;
        this.showEP.ep3 = false;
        this.showEP.ep4 = false;
        this.showEP.ep5 = false;
        this.showEP.ep6 = false;
        this.showEP.ep7 = false;
      } else if (ep == "2") {
        if (this.showEP.ep2 == true) {
          this.showEP.ep2 = false;
        } else {
          this.showEP.ep2 = true;
        }

        this.showEP.ep1 = false;
        this.showEP.ep3 = false;
        this.showEP.ep4 = false;
        this.showEP.ep5 = false;
        this.showEP.ep6 = false;
        this.showEP.ep7 = false;
      }
      if (ep == "3") {
        if (this.showEP.ep3 == true) {
          this.showEP.ep3 = false;
        } else {
          this.showEP.ep3 = true;
        }

        this.showEP.ep1 = false;
        this.showEP.ep2 = false;
        this.showEP.ep4 = false;
        this.showEP.ep5 = false;
        this.showEP.ep6 = false;
        this.showEP.ep7 = false;
      }
      if (ep == "4") {
        if (this.showEP.ep4 == true) {
          this.showEP.ep4 = false;
        } else {
          this.showEP.ep4 = true;
        }

        this.showEP.ep1 = false;
        this.showEP.ep2 = false;
        this.showEP.ep3 = false;
        this.showEP.ep5 = false;
        this.showEP.ep6 = false;
        this.showEP.ep7 = false;
      }
      if (ep == "5") {
        if (this.showEP.ep5 == true) {
          this.showEP.ep5 = false;
        } else {
          this.showEP.ep5 = true;
        }

        this.showEP.ep1 = false;
        this.showEP.ep2 = false;
        this.showEP.ep3 = false;
        this.showEP.ep4 = false;
        this.showEP.ep6 = false;
        this.showEP.ep7 = false;
      }
      if (ep == "6") {
        if (this.showEP.ep6 == true) {
          this.showEP.ep6 = false;
        } else {
          this.showEP.ep6 = true;
        }

        this.showEP.ep1 = false;
        this.showEP.ep2 = false;
        this.showEP.ep3 = false;
        this.showEP.ep4 = false;
        this.showEP.ep5 = false;
        this.showEP.ep7 = false;
      }
      if (ep == "7") {
        if (this.showEP.ep7 == true) {
          this.showEP.ep7 = false;
        } else {
          this.showEP.ep7 = true;
        }

        this.showEP.ep1 = false;
        this.showEP.ep2 = false;
        this.showEP.ep3 = false;
        this.showEP.ep4 = false;
        this.showEP.ep5 = false;
        this.showEP.ep6 = false;
      }
    },
  },
};
</script>

<style lang="scss">
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  color: black;
  background-image: linear-gradient(#ffffff, #cacaca);
  transition: 0.3s;
  border-radius: 10px;
  padding: 20px;
  height: 19em;
  overflow: hidden;
  width: 100%;
}

.vertical-nav-active {
  display: block;
}
</style>

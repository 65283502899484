<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter clear-filter"
      parallax-active="false"
      :style="headerStyle"
    >
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="image-wrapper">
            <div class="brand">
              <h1 class="text-color">
                <b style="font-size: 60px; font-weight: 800">AUTO REBALANCE</b>
              </h1>
              <h2
                v-if="getLanguage == 'THAI'"
                style="font-size: 30px; font-weight: 600"
                class="text-color"
              >
                บอทเทรดของนักเทรดมืออาชีพ
              </h2>
              <h2
                v-else
                style="font-size: 30px; font-weight: 600"
                class="text-color"
              >
                Trading bots of professional traders
              </h2>
            </div>
          </div>
        </div>
      </div>
    </parallax>
    <firstComponent />

    <components />
  </div>
</template>

<script>
import firstComponent from "./presentation/firstComponent.vue";
import Components from "./presentation/components.vue";

import Mixins from "@/plugins/basicMixins";
import { mapGetters } from "vuex";

export default {
  components: {
    Components,
    firstComponent,
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "presentation-page",
  computed: mapGetters(["getLanguage"]),

  data() {
    return {
      image: require("../assets/img/ARimg/background.png"),
      leafShow: false,
    };
  },
  mounted() {
    this.leafActive();
    window.addEventListener("resize", this.leafActive);
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.leafActive);
    document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    leafActive() {
      if (window.innerWidth < 768) {
        this.leafShow = false;
      } else {
        this.leafShow = true;
      }
    },
  },
};
</script>

<style lang="scss"></style>

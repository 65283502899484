<template>
  <div class="section" style="background-color: #020a2a; color: white">
    <div class="container">
      <div class="md-layout">
        <div
          class="md-layout-item md-medium-size-100 md-small-size-100 mx-auto"
        >
          <h4 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.header_1 }}
          </h4>
          <h4 v-else>
            {{ autorebalance_th.header_1 }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import autorebalance_en from "../../language/autorebalance_en";
import autorebalance_th from "../../language/autorebalance_th";

export default {
  name: "firstComponent",
  computed: mapGetters(["getLanguage"]),

  data() {
    return {
      autorebalance_en,
      autorebalance_th,
    };
  },
};
</script>

<style lang="css"></style>

module.exports = {
  en_title: "BACK TEST",
  en_set_time: "SET REBALANCE",
  en_set_summary: "SET STRATING AMOUNT REBALANCE",
  en_set_assete: "SELECT ASSETS",
  en_style_button: "CALCULATR",
  en_warning:
    "However, usage of AutoRebalance ,  Backtest and Forwardtest cannot guarantee future results or profits. Services contain risk. Not recommended to use it without research. Users should study risks before deciding to use services.",
  en_transaction: "Transaction",
};

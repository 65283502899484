module.exports = {
  header_1:
    "Auto Rebalance or the use of robots to modify digital assets in cryptocurrency portfolios. for risk management in the digital age and maintaining the principal for a higher value in the long run This strategy can be used in other assets. but with high liquidity high risk high volatility fast time high yield Low management costs, 24-hour market, make the cryptocurrency market the best solution. for this strategy",
  auto_rebalance:
    "In the investing world, rebalancing refers to the process of modifying portfolio assets to maintain the desired level of risk and asset allocation. Rebalancing allows investors to achieve their overall investment goals over the long term. Invest to support all market conditions And through the testing of many periods, it is a key component of any investor's long-term investment plan. However, in general, this strategy is based on the higher the value of the portfolio, the more profitable it is. Many investors are unsure of the underlying logic and thought process behind the Rebalancing strategy and refuse to fully understand and study it. Because investors tend to look primarily at short-term returns. Rather than recognizing investment risks, is the goal to increase returns, reduce risks, or perhaps combine both?",
  title_when_rebalance: "When should I Rebalancing ?",
  description_when_rebalance:
    "Although the answer may not be immediately clear. But in this section, we hope to clarify several key points as to why rebalancing is an important aspect of any investment portfolio's financial planning.",
  reason_rebalance: "There are two reasons why rebalancing is important.",
  reason_title_1: "1. Rebalancing your investment portfolio helps manage risk.",
  reason_detail_1:
    "One of the key principles behind asset allocation is the idea that not all assets move in the same direction at the same time. A diversified portfolio is important as it minimizes volatility based on the investor's pre-determined goals. However, the asymmetric performance of positions in the portfolio also leads to allocation deferrals, which will cause the portfolio risk profile to shift away from the strategic plan. The image below shows two portfolios starting with BTC and a simplified USD 50/50, one being rebalanced every 10 days and the other not being rebalanced over a 10-year period. Consequences” of letting the portfolio float without rebalancing, if strategically weighted and ignoring the risk, are likely to increase over time due to the high volatility of the bitcoin price. a lot make investment portfolio allocation It is of great importance to investment. We can adjust the portfolio by trading BTC for USD along the way which makes it possible to reduce the risk more. (Therefore, higher expected returns), although holding BTC alone may be more profitable. Of course, this is not a certainty as the portfolio risk can be reduced over a specific period of time. In either scenario, the portfolio's risks are inconsistent with the expected returns.",
  reason_detail_1_1:
    "Rebalancing at every price level change or adjusting the pot every day and adjusting the pot every day Changing the current Balance balance may be an alternative to port rebalancing analysis. To help reduce the volatility of the investment portfolio. While the returns may be less than the holdings, over the long term, the amount of BTC and USD may be more than a passive investment. The scenarios selected below are just examples and do not include some real-world costs such as transaction costs, taxes and management fees, etc. We also believe in actively pursuing portfolio rebalancing opportunities. continuously (compared to periodic assessments)",
  reason_title_2: "2. Rebalancing helps avoid emotional investment decisions.",
  reason_detail_2:
    "Over the course of an investor's life there are periods of increasing volatility. Regardless, this comes in the form of a short-term market shock. or a prolonged recession (or even bulls) emotionally often undermines rationality and can turn investors away from long-term goals at the worst of times. This includes reducing the allocation to risky assets in the market. After a steep sell-off or over-allocation after a significant increase in value, for example, we highlight the bottom two periods where the market continued to rise before a sharp drop. In these examples we can see that unbalanced portfolios offer better returns while stocks rise, however, portfolios suffer more severe losses during downtrends. Rebalancing will be able to turn a crisis into a more profitable one in the long run.",
  reason_detail_2_2:
    "We can see that during 2008, non-rebalancing portfolios suffered severe losses that wiped out previous years' profits. Investors could be forced to lose more of their portfolio values at that time. Since they are not rebalancing, it takes a waiting period for the price to rise in order to regain lost profits.",
  reason_detail_2_3: `We saw this again during the March 2020 bull run. During COVID-19, portfolios that do not adhere to rebalancing guidelines will be affected by the sharp drop in volatility in March, which makes it much harder for investors to invest. Fortunately, there is a sharp reversal in the stock market. Otherwise, downtrend volatility will be more painful than investors expect. Transaction Costs - Keeping costs and fees low is one of the core principles of our investment philosophy. For this reason, we have to take turnover very seriously because of the obvious cost. (commissions) and costs that cannot be avoided (spread and market impact) even with very low commissions But it is necessary to avoid over-traded portfolios. which may have a negative effect in the long term In order to minimize transaction costs, we can choose to adjust the portfolio with every increase or decrease in the portfolio at that time, according to the overall value of the total equity currently available. by letting robots trade It serves to calculate, for example, when the portfolio changes by 10%, it will adjust the investment portfolio around. If there is a lot of volatility, it has a positive effect on the overall portfolio. This allows the portfolio to be rebalanced “naturally” by rebalancing the cash flow generated by the long-term portfolio adjustments.
  There are 5 strategies for Rebalancing that Auto Rebalance has researched, which here will only mention 2 strategies.
  Amount - Determines the amount of rebalancing that increases and decreases in value in the portfolio.
  The “high point” of this type of rebalancing is that there is no need to monitor investment opportunities. They are rebalancing regardless of current price or strategic allocation. Any other techniques that must be considered in trading but is calculated from The amount of the portfolio changes according to the amount, for example, the portfolio value is 10,000 USDT will be rebalancing every $100. This strategy doesn't care how much the portfolio grows. but will always adjust the port every time the port value changes according to the setting.
  Percent - Determines as the value increases and decreases in the portfolio, periodically rebalancing.
  The “high point” of this type of rebalancing is that there is no need to monitor investment opportunities. They are rebalancing regardless of current price or strategic allocation. Any other techniques that must be considered in trading In our view, this method produces much better results due to the volatility of prices between different asset classes. therefore profit from the price fluctuation without having to depend on waiting time alone To use this method effectively, it is necessary to take advantage of technology that allows continuous monitoring while making decisions. “The Risk Management Strategy of the Digital Age”, also known as “Auto Rebalance”. Unfortunately, there are no criteria. When making this decision, we must weigh the delicate balance between allowing the allocation to fluctuate from the two strategies above, by "letting the strategy we choose to execute" and cost. transaction related to rebalancing to manage investment portfolios`,
  final_title: "Final point",
  final_detail:
    "Investors are human beings and are prone to emotional and behavioral biases. This appropriate rebalancing plan ensures that investors stay and continue to invest throughout the volatile market cycles in the cryptocurrency market. As an added benefit, it may help to increase the return that is more certain over the past 5 years of consulting clients. We have found the fact that clients do not increase their chances of success in achieving their financial goals by taking a higher risk in their portfolios. Thus, it can be concluded that Auto Rebalance is considered to be the heart of a risk management strategy that primarily provides long-term returns. There may be a quick payoff during an uptrend. Makes me feel that the investment portfolio is quite profitable. But one thing to be cautious about is when asset prices drop. Will cause the portfolio value to decrease as well, but the investment portfolio is always liquid. to spend money in emergencies or when there are various crises without having to sell some of the assets unnecessarily And it is the use of robots to transform digital assets in cryptocurrency portfolios. for risk management in the digital age and maintaining the principal for a higher value in the long run",
  warning_title: "Warning",
  warning_1:
    "1. Investment is risky. Investors should study the information before making an investment decision.",
  warning_2:
    "2. Auto Rebalance is just a software for the Rebalance Port service only, not an investment solicitation. without taking deposit of investors' funds no dividend contract no income guarantee There is no obligation to compensate for losses. or requesting profits generated by investors or any other benefits",
  warning_3: "3. Past performance cannot guarantee the present and the future",
  warning_4:
    "4. Investors should study and understand this risk management strategy thoroughly before making an investment decision. 5. This document was prepared on 11/1/2021.",

  warning_5:
    "*** Auto Rebalance is made for academic reference. And the benefit of studying, researching, comparing information for decision making only for those who are interested. ***",
};

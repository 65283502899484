<template>
  <div
    class="section section-components pt-0"
    style="background-color: #020a2a; color: white"
  >
    <div class="container">
      <div class="md-layout">
        <div class="md-layout-item md-size-90 md-small-size-100">
          <h3>Auto Rebalance</h3>

          <h5 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.auto_rebalance }}
          </h5>
          <h5 v-else>
            {{ autorebalance_th.auto_rebalance }}
          </h5>
        </div>

        <div class="md-layout-item md-size-100 md-small-size-100">
          <h3 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.title_when_rebalance }}
          </h3>
          <h3 v-else>
            {{ autorebalance_th.title_when_rebalance }}
          </h3>

          <h5 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.description_when_rebalance }}
          </h5>
          <h5 v-else>
            {{ autorebalance_th.description_when_rebalance }}
          </h5>

          <h3 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.reason_rebalance }}
          </h3>
          <h3 v-else>
            {{ autorebalance_th.reason_rebalance }}
          </h3>

          <h4 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.reason_title_1 }}
          </h4>
          <h4 v-else>
            {{ autorebalance_th.reason_title_1 }}
          </h4>

          <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
            <img src="../../assets/img/autorebalance/01.png" alt="" />
          </div>

          <h5 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.reason_detail_1 }}
          </h5>
          <h5 v-else>
            {{ autorebalance_th.reason_detail_1 }}
          </h5>

          <h5 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.reason_detail_1_1 }}
          </h5>
          <h5 v-else>
            {{ autorebalance_th.reason_detail_1_1 }}
          </h5>

          <h4 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.reason_title_2 }}
          </h4>
          <h4 v-else>
            {{ autorebalance_th.reason_title_2 }}
          </h4>

          <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
            <img src="../../assets/img/autorebalance/06.png" alt="" />
          </div>

          <h5 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.reason_detail_2 }}
          </h5>
          <h5 v-else>
            {{ autorebalance_th.reason_detail_2 }}
          </h5>

          <h5 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.reason_detail_2_2 }}
          </h5>
          <h5 v-else>
            {{ autorebalance_th.reason_detail_2_2 }}
          </h5>

          <h5 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.reason_detail_2_3 }}
          </h5>
          <h5 v-else>
            {{ autorebalance_th.reason_detail_2_3 }}
          </h5>

          <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
            <img src="../../assets/img/autorebalance/07.png" alt="" />
          </div>

          <h3 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.final_title }}
          </h3>
          <h3 v-else>
            {{ autorebalance_th.final_title }}
          </h3>

          <h4 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.final_detail }}
          </h4>
          <h4 v-else>
            {{ autorebalance_th.final_detail }}
          </h4>

          <div class="md-layout-item md-size-50 md-small-size-100 mx-auto">
            <img src="../../assets/img/autorebalance/08.png" alt="" />
          </div>

          <h3 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.warning_title }}
          </h3>
          <h3 v-else>
            {{ autorebalance_th.warning_title }}
          </h3>

          <h5 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.warning_1 }}
            <br />
            {{ autorebalance_en.warning_2 }}

            <br />
            {{ autorebalance_en.warning_3 }}
            <br />
            {{ autorebalance_en.warning_4 }}
          </h5>
          <h5 v-else>
            {{ autorebalance_th.warning_1 }}
            <br />
            {{ autorebalance_th.warning_2 }}

            <br />
            {{ autorebalance_th.warning_3 }}
            <br />
            {{ autorebalance_th.warning_4 }}
          </h5>

          <h5 v-if="getLanguage == 'ENG'">
            {{ autorebalance_en.warning_5 }}
          </h5>
          <h5 v-else>
            {{ autorebalance_th.warning_5 }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import autorebalance_en from "../../language/autorebalance_en";
import autorebalance_th from "../../language/autorebalance_th";

export default {
  name: "Components",
  computed: mapGetters(["getLanguage"]),

  data() {
    return {
      sectionComponents: {
        img1: require("@/assets/img/section-components/laptop-basics.png"),
        img2: require("@/assets/img/section-components/table.jpg"),
        img3: require("@/assets/img/section-components/share-btn.jpg"),
        img4: require("@/assets/img/section-components/coloured-card-with-btn.jpg"),
        img5: require("@/assets/img/section-components/coloured-card.jpg"),
        img6: require("@/assets/img/section-components/social-row.jpg"),
        img7: require("@/assets/img/section-components/pin-btn.jpg"),
      },
      autorebalance_en,
      autorebalance_th,
    };
  },
};
</script>

<style lang="css"></style>

<template>
  <div class="wrapper">
    <div
      class="section section-testimonials-1"
      style="background-image: linear-gradient(#000000, #001a26)"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-100 mx-auto text-center"
          >
            <h1
              style="font-size: 40px; font-weight: 600; margin: 50px"
              class="text-orange"
            >
              <b
                v-if="getLanguage == 'THAI'"
                style="font-size: 50px; font-weight: 800"
                >รีวิว</b
              >
              <b v-else style="font-size: 60px; font-weight: 800">REVIEWS</b>
            </h1>
          </div>
        </div>
        <div class="md-layout">
          <div
            class="md-layout-item md-size-33 md-small-size-100"
            style="margin-top: 20px"
          >
            <div class="card">
              <template>
                <iframe
                  style="border-radius: 10px"
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/mzmI6QofeRE"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h4 style="font-weight: 500">EP3 ถอนกำไร</h4>

                <a style="color: black">
                  Bitดอย Chanel : EP3 ถอนกำไร 40,000 บาท มาซื้อกล้อง
                  "ยังเหลือกำไร 7xx,xxx บาท
                </a>
              </template>
            </div>
          </div>
          <div
            class="md-layout-item md-size-33 md-small-size-100"
            style="margin-top: 20px"
          >
            <div class="card">
              <template>
                <iframe
                  style="border-radius: 10px"
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/nADdItG3ASU"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h4 style="font-weight: 500">EP.2 Auto Rebalancing Robot</h4>

                <a style="color: black">
                  EP.2 Auto Rebalancing Robot on Binance กำไรวันเดียว 26,000
                  บาท(Clickbaitนะ)
                </a>
              </template>
            </div>
          </div>
          <div
            class="md-layout-item md-size-33 md-small-size-100"
            style="margin-top: 20px"
          >
            <div class="card">
              <template>
                <iframe
                  style="border-radius: 10px"
                  width="950"
                  height="585"
                  src="https://www.youtube.com/embed/LtHdQU9sGPY"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h4 style="font-weight: 500">สุดยอดบอทบริหารความเสี่ยง</h4>

                <a style="color: black">
                  Auto Rebalance สุดยอดบอทบริหารความเสี่ยง รีวิว by คุณไก่
                  ออนไลน์
                </a>
              </template>
            </div>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item" style="margin-top: 20px">
            <div class="card">
              <template>
                <iframe
                  style="border-radius: 10px"
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/_dEmeO_5dbo"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h4 style="font-weight: 500">EP1 ไม่รู้เรื้อง Bitcoin</h4>

                <a style="color: black">
                  Bitดอย Chanel : EP1 ไม่รู้เรื้อง Bitcoin แค่เดือนเดียว
                  ทำกำไรไปเกือบ 70,000 บาท
                </a>
              </template>
            </div>
          </div>
          <div
            class="md-layout-item md-size-33 md-small-size-100"
            style="margin-top: 20px"
          >
            <div class="card">
              <template>
                <iframe
                  style="border-radius: 10px"
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/0DIjEySLnTM"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h4 style="font-weight: 500">Bitดอย Chanel</h4>

                <a style="color: black">
                  Bitดอย Chanel : EP4 ราคา Bitcoin ร่วงหนักขนาดนี้ "Auto
                  Rebalance" ยังมีกำไรอยู่มั้ย ?
                </a>
              </template>
            </div>
          </div>
          <div
            class="md-layout-item md-size-33 md-small-size-100"
            style="margin-top: 20px"
          >
            <div class="card">
              <template>
                <iframe
                  style="border-radius: 10px"
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/df5L5YbRS34"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h4 style="font-weight: 500">EP2 ถาม-ตอบ</h4>

                <a style="color: black">
                  Bitดอย Chanel : EP2 ถาม-ตอบ Q&A "คำถามที่หลายคนสงสัย
                  หากจะเริ่ม Rebalance"
                </a>
              </template>
            </div>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item" style="margin-top: 20px">
            <div class="card">
              <template>
                <iframe
                  style="border-radius: 10px"
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/c5p68IEvYB4"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h4 style="font-weight: 500">วิธี Rebalance ในตลาด Crypto</h4>

                <a style="color: black">
                  วิธี Rebalance ในตลาด Crypto Currency #การลงทุนมีความเสี่ยง
                  ควรศึกษาข้อมูลก่อนตัดสินใจลงทุน
                </a>
              </template>
            </div>
          </div>
          <div
            class="md-layout-item md-size-33 md-small-size-100"
            style="margin-top: 20px"
          >
            <div class="card">
              <template>
                <iframe
                  style="border-radius: 10px"
                  width="950"
                  height="534"
                  src="https://www.youtube.com/embed/veNm6gpC5CE"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <h4 style="font-weight: 500">Rebalancing</h4>

                <a style="color: black">
                  EP.1 Bitcoin Invest Research Rebalancing
                </a>
              </template>
            </div>
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100"></div>
        </div>
      </div>
    </div>
    <!-- end Testimonials 1 -->

    <div
      class="section"
      style="background-image: linear-gradient(#001a26, #000000); color: white"
    >
      <div class="container">
        <h2 v-if="getLanguage == 'THAI'">
          <b style="font-weight: 800" class="text-orange"> บทความรีวิว</b>
        </h2>

        <h2 v-else>
          <b style="font-weight: 800" class="text-orange"> ARTICLE REVIEWS </b>
        </h2>

        <br />
        <div class="md-layout">
          <div class="md-layout-item md-size-25 md-small-size-100">
            <center>
              <br />

              <a
                target="_blank"
                href="https://www.facebook.com/Cryptofourmink/photos/a.115590341120340/143950254951015/?type=3&mibextid=wxGVb6"
              >
                <img
                  class=""
                  style="border-radius: 50%; width: 70%"
                  src="@/assets/img/reviews/crypto_four.jpeg"
                />
                <h3 style="font-weight: 600" class="text-orange">
                  Crypto fourmink
                </h3>
              </a>
            </center>
          </div>

          <div class="md-layout-item md-size-25 md-small-size-100">
            <br />

            <center>
              <a
                target="_blank"
                href="https://www.facebook.com/Cryptoqueenth/photos/a.114588240897434/189959226693668/?type=3&mibextid=wxGVb6"
              >
                <img
                  class=""
                  style="border-radius: 50%; width: 70%"
                  src="@/assets/img/reviews/crypto_queen.jpeg"
                />
                <h3 style="font-weight: 600" class="text-orange">
                  ริปโตตัวแม่-Cryptoqueen
                </h3>
              </a>
            </center>
          </div>

          <div class="md-layout-item md-size-25 md-small-size-100">
            <center>
              <br />

              <a
                target="_blank"
                href="https://www.facebook.com/110881201065325/posts/pfbid02DUxFFtkihS1n2epQQKGcu6jaVF1rFAPNx6YihdDzicHDsXmeUzVZEERdxRdzzRjpl/?d=n&mibextid=wxGVb6"
              >
                <img
                  class=""
                  style="border-radius: 50%; width: 70%"
                  src="@/assets/img/reviews/cryptoTeller.jpeg"
                />
                <h3 style="font-weight: 600" class="text-orange">
                  Crypto Teller บอกกล่าวเล่าคริปโต
                </h3>
              </a>
            </center>
          </div>

          <div class="md-layout-item md-size-25 md-small-size-100">
            <center>
              <br />

              <a
                target="_blank"
                href="https://cryptonews.in.th/auto-rebalance-strategy-minimize-your-crypto-investment-portfolio-with-rebalancing-bots/?fbclid=IwAR1WJ9CNcS3Jb8oIgPo88PDUfa40wGeZ52jE4OecgFy1Phm-meApLjJauyY"
              >
                <img
                  class=""
                  style="border-radius: 50%; width: 70%"
                  src="@/assets/img/reviews/crypto_new.jpeg"
                />
                <h3 style="font-weight: 600" class="text-orange">
                  Crypto News
                </h3>
              </a>
            </center>
          </div>

          <div class="md-layout-item md-size-25 md-small-size-100">
            <br />
            <br />
            <br />
            <center>
              <a
                target="_blank"
                href="https://region.siamrath.co.th/bkk/Bangkok/31758?fbclid=IwAR1Hl876lpt9gijvKSry82tivdn8NkA9tYg2Cwawn68aZFLIdiqRqrhgJ7c"
              >
                <img
                  class=""
                  style="width: 90%"
                  src="@/assets/img/reviews/siamrath-logo.png"
                />
                <h3 style="font-weight: 600" class="text-orange">สยามรัฐ</h3>
              </a>
            </center>
          </div>

          <div class="md-layout-item md-size-25 md-small-size-100">
            <br />
            <br />
            <br />
            <center>
              <a
                target="_blank"
                href="https://longtuncrypto.com/review-of-10-crypto-trading-bots-the-best-in-2022/?fbclid=IwAR3pDcKQhPm4iGU-RNgKaFNEsEwcfwTbDMKzJ5BNt3eRBdE80eA-004-vAo"
              >
                <img
                  class=""
                  style="width: 90%"
                  src="@/assets/img/reviews/logo1a-v1.jpeg"
                />
                <h3 style="font-weight: 600" class="text-orange">
                  ลงทุนคริปโต
                </h3>
              </a>
            </center>
          </div>
        </div>

        <br />
        <br />

        <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
          <div class="md-layout">
            <div
              class="md-layout-item md-size-25 md-small-size-33"
              style="margin-top: 10px"
              v-for="data in imgReviews"
              :key="data"
            >
              <img class="img-raised rounded img-fluid" :src="data.image" />
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <div
                  style="
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 30px;
                    margin-right: 15px;
                  "
                >
                  <a
                    target="_blank"
                    href="https://www.facebook.com/autorebalance/photos/?ref=page_internal"
                    style="
                      border-style: solid;
                      border-color: white;
                      border-radius: 5px;
                    "
                  >
                    <a style="padding: 5px; color: white; font-size: 12px">
                      Read more >>
                    </a>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            class="md-layout-item md-small-size-100 mx-auto"
            style="margin-top: 10px"
          >
            <h2
              v-if="getLanguage == 'THAI'"
              style="color: #f3ef07; font-weight: 600"
            >
              คำเตืออน
            </h2>

            <h2 v-else style="color: #f3ef07; font-weight: 600">Caution</h2>
            <h4
              v-if="getLanguage == 'THAI'"
              style="color: #f3ef07; font-weight: 500"
            >
              “Autorebalanceไม่ใช่การชักชวนลงทุน
              หรือผู้ให้คำแนะนำการลงทุนและการใช้งานดังกล่าวมีความเสี่ยง
              เราไม่สามารถการันตีผลลัพธ์ได้ว่ากำไรหรือขาดทุนเหมือนตัวอย่างที่รีวิว”
              “ไม่แนะนำให้ใช้งานหากไม่ทำการศึกษาข้อมูล
              ข้อจำกัดและยอมรับเงื่อนไขการใช้งาน
              และยอมรับความเสี่ยงก่อนติดสินใจใช้งาน” “Autorebalance
              เป็นเพียงผู้ให้บริการ บอทเทรดเท่านั้น” “การลงทุนมีความเสี่ยง
              ผู้ลงทุนควรศึกษาข้อมูล และยอมรับความเสี่ยงก่อนตัดสินใจลงทุน”
              <br />
            </h4>

            <h4 v-else style="color: #f3ef07; font-weight: 500">
              “Autorebalance is not an investment solicitation. or investment
              advisor and such use is risky. We cannot guarantee results that
              the profit or loss is the same as the reviewed example.”
              Restrictions and Acceptance of Terms of Use and accept the risk
              before deciding to use it” “Autorebalance is just a service
              provider Trading bots only.” “Investment is risky. Investors
              should study the information. and accept the risk before making an
              investment decision.”
              <br />
            </h4>
          </div>
        </div>
      </div>
    </div>
    <nav id="cd-vertical-nav" class="vertical-nav-active">
      <ul>
        <li>
          <a
            href="https://www.facebook.com/autorebalance"
            target="_blank"
            data-number="1"
          >
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #0f47f7"
                class="fab fa-facebook"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a href="http://line.me/ti/g/fqQ5X1GmYP" target="_blank">
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #5afa33"
                class="fab fa-line"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a href="https://www.instagram.com/aiautorebalance/" target="_blank">
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: #fa33af"
                class="fab fa-instagram"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>

        <li>
          <a href="https://medium.com/@aiautorebalance" target="_blank">
            <span class="cd-dot-2">
              <i
                style="font-size: 54px; color: white"
                class="fab fa-medium"
              ></i>
            </span>
            <span class="cd-label-2">Auto Rebalance</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Reviews",
  components: {},
  computed: mapGetters(["getLanguage"]),

  data() {
    return {
      testimonials1: require("@/assets/img/dg2.jpg"),
      cardBlogPost: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2022/02/S__5226628.jpg",
      },
      cardBlogPost2: {
        cardBlogPost1:
          "https://cryptonews.in.th/wp-content/uploads/2021/03/1_A1x1XR7PJEhZiSqSNWxvvw.png",
      },
      cardBlogPost3: {
        cardBlogPost1:
          "https://scontent.fbkk2-4.fna.fbcdn.net/v/t39.30808-6/274545843_493958235592183_8891905078477806124_n.jpg?_nc_cat=101&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeFtwmjIM0s5aKuP3FP_EKWE9KvFs1u-Bsj0q8WzW74GyHof3DbJchYdAFLJSwOAt-0jBuSrShuHTLMHvmqHAnGz&_nc_ohc=Rc2aeFz0ZeEAX8GMXjR&tn=j0WnZqtCr_hzpKka&_nc_ht=scontent.fbkk2-4.fna&oh=00_AT_hRjG8shrQy4ER-lJWkKeH9omKC4oRT-YFgJqbFjAuog&oe=622664C0",
      },
      imgReviews: [
        {
          image: require("@/assets/img/reviews/1.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/2.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/3.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/4.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/5.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/6.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/7.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/8.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/9.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/10.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/11.jpeg"),
        },
        {
          image: require("@/assets/img/reviews/12.jpeg"),
        },
        // {
        //   image: require("@/assets/img/reviews/13.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/14.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/15.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/16.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/17.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/18.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/19.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/20.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/21.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/22.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/23.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/24.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/25.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/26.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/27.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/28.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/29.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/30.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/31.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/32.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/33.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/34.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/35.jpeg"),
        // },
        // {
        //   image: require("@/assets/img/reviews/36.jpeg"),
        // },
      ],
    };
  },
  methods: {
    sectionTestimonials(image) {
      return {
        backgroundImage: `url(${image})`,
      };
    },
  },
};
</script>

<style lang="scss">
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  color: black;
  background-image: linear-gradient(#ffffff, #cacaca);
  transition: 0.3s;
  border-radius: 10px;
  padding: 20px;
  height: 19em;
  overflow: hidden;
  width: 100%;
}

.vertical-nav-active {
  display: block;
}
</style>

<template>
  <div class="wrapper">
    <!-- Features 1 -->
    <div
      class="section"
      style="
        background-image: linear-gradient(#001a26, #000000);
        color: white;
        margin-top: -100px;
      "
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-30 md-small-size-50"
            style="margin-top: 20px"
          >
            <a href="https://poloniex.com/spot/BTC_USDT" target="_blank">
              <img
                src="../../assets/img/ARimg/Support-11.svg"
                style=""
                alt=""
              />
            </a>
          </div>
          <div
            class="md-layout-item md-size-30 md-small-size-50"
            style="margin-top: 20px"
          >
            <a href="binance.com/en" target="_blank">
              <img src="../../assets/img/ARimg/Support-08.svg" />
            </a>
          </div>
          <div
            class="md-layout-item md-size-30 md-small-size-50"
            style="margin-top: 20px"
          >
            <a href="https://www.bitkub.com/" target="_blank">
              <img src="../../assets/img/ARimg/Support-10.svg" />
            </a>
          </div>
          <div class="md-layout-item md-size-30 md-small-size-50">
            <a href="https://www.kraken.com/u/verify/flow" target="_blank">
              <img src="@/assets/img/ARimg/kraken.svg" />
            </a>
          </div>
          <div
            class="md-layout-item md-size-30 md-small-size-50"
            style="margin-top: 20px"
          >
            <center>
              <div style="max-width: 80%">
                <a href="https://www.bybit.com/en-US" target="_blank">
                  <img
                    src="https://alphaimpact.fi/wp-content/uploads/2022/09/bybit-logo.png"
                  />
                </a>
              </div>
            </center>
          </div>
          <div
            class="md-layout-item md-size-30 md-small-size-50"
            style="margin-top: 20px"
          >
            <a href="https://app.1inch.io/" target="_blank">
              <img src="../../assets/img/ARimg/Support.svg" />
            </a>
          </div>

          <div
            class="md-layout-item md-size-30 md-small-size-50"
            style="margin-top: 10px"
          >
            <center>
              <div style="max-width: 80%">
                <a href="https://www.kucoin.com/th" target="_blank">
                  <img src="@/assets/img/ARimg/kucoin.svg" />
                </a>
              </div>
            </center>
          </div>

          <div
            class="md-layout-item md-size-30 md-small-size-50"
            style="margin-top: 10px"
          >
            <center>
              <div style="max-width: 80%">
                <a href="www.gemini.com" target="_blank">
                  <img
                    src="https://www.gemini.com/_next/static/images/gemini-hor-light-full-rgb@3x-c21cfe67fefd5e84c5f4516b297c72fa.png"
                  />
                </a>
              </div>
            </center>
          </div>

          <div
            class="md-layout-item md-size-30 md-small-size-50"
            style="margin-top: 10px"
          >
            <center>
              <div style="max-width: 80%">
                <a href="https://www.bitfinex.com/" target="_blank">
                  <img src="@/assets/img/ARimg/bit.svg" />
                </a>
              </div>
            </center>
          </div>
        </div>
      </div>
    </div>
    <!-- end Features 1 -->

    <!-- end Features 5 -->
  </div>
</template>

<script>
export default {
  name: "supports",
  components: {},
  data() {
    return {
      iphone: require("@/assets/img/sections/iphone.jpg"),
      iphone2: require("@/assets/img/sections/iphone2.png"),
      image: require("@/assets/img/bg9.jpg"),
    };
  },
  computed: {
    features5() {
      return {
        backgroundImage: `url(${this.image})`,
      };
    },
  },
};
</script>

<style lang="scss"></style>
